@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

.embedded-content .about,
.embedded-content .for-business,
.embedded-content .for-people,
.embedded-content .help,
.embedded-content .not-found,
.embedded-content .how-to-use,
.embedded-content .where-to-use,
.embedded-content .link-card,
.embedded-content .register,
.embedded-content .security,
.embedded-content .banking,
.embedded-content .promo,
.embedded-content .register-end {
  margin-top: 0 !important;
}

.embedded-content {
  margin-top: 0 !important;
}

.paragraph-list {
  counter-reset: item;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  margin: 20px 0px 60px 60px;
}

.paragraph-list p {
  margin: 0;
  /* Remove default paragraph margins */
  font-size: 25px;
  text-align: left;
  text-indent: -28px;
  /* Adjust this value as needed */
}

.paragraph-list-item {
  text-align: left !important;

}

.paragraph-list p:before {
  content: counter(item, lower-alpha) ". ";
  /* Add counter value and period */
  counter-increment: item;
  /* Increment counter for each paragraph */
}


body {
  font-family: "Poppins", sans-serif;
  color: #454f5b;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fredoka One", cursive;
  font-weight: normal;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  position: relative;
}

h2 {
  font-family: "Fredoka One", cursive;
  font-size: 45px;
  line-height: 54px;
  color: #454f5b;
  text-align: center;
  max-width: 850px;
  margin-right: auto;
  margin-left: auto;
}

h4 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 25px;
}

.understandingLetter {
  padding-right: 35px;
}

.understandingLetter img {
  max-width: 806px;
  width: 100%;
  height: auto;
}


p {
  font-size: 20px;
  color: #454f5b;
  font-weight: 300;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

.test123 {
  font-size: 20px !important;
}

.switchStepTabBtnContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.freeDataDivContainer {
  text-align: center;
  margin-bottom: 40px;
}

.freeDataDivContainer img {
  max-width: 650px;
}

.btn-switch-tab-green {
  display: block;
  background-color: rgb(4, 123, 141);
  color: #ffffff;
  font-size: 20px;
  width: 260px;
  height: 45px;
  color: #ffffff;
  border-radius: 500px;
  border: 2px solid;
  border-color: transparent;
  text-align: center;
  font-weight: bold;
  line-height: 41px;
  margin: auto;
}

.btn-switch-tab-purple {
  display: block;
  background-color: #743c7a;
  color: #ffffff;
  font-size: 20px;
  width: 260px;
  height: 45px;
  color: #ffffff;
  border-radius: 500px;
  border: 2px solid;
  border-color: transparent;
  text-align: center;
  font-weight: bold;
  line-height: 41px;
  margin: auto;

}

.btn-switch-tab-black {
  display: block;
  background-color: #2F3542;
  color: #ffffff;
  font-size: 20px;
  width: 260px;
  height: 45px;
  color: #ffffff;
  border-radius: 500px;
  border: 2px solid;
  border-color: transparent;
  text-align: center;
  font-weight: bold;
  line-height: 41px;
  margin: auto;

}

.otpPatient {
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}

.share-asset-space {
  height: 0px !important;
}

.btn-share-xtra-link-btn-container {
  margin-top: 40px;
}

.xtra-questions-header {
  margin-bottom: 20px !important;
}

.xtra-questions-sub-header {
  margin-bottom: 40px;
}

.btn-share-xtra-link {
  display: flex;
  background-color: #303541;
  color: #ffffff;
  font-size: 20px;
  width: 220px;
  height: 60px;
  color: #ffffff;
  border-radius: 500px;
  border: 2px solid;
  border-color: transparent;
  text-align: center;
  font-weight: bold;
  line-height: 41px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.btn-share-xtra-link img {
  margin-right: 10px;
  width: 30px;
}

.tabBottomTextContainer {
  display: flex;
}

.tabBottomText {
  font-size: 16px;
  color: #047B8d;
  font-weight: bold;
  margin-right: 10px;
}

.bottomeStoreImage {
  margin-right: 5px;
  height: 26px;
}

.btn-share-whatsapp {
  display: block;
  background-color: #0a9ba9;
  color: #ffffff;
  font-size: 20px;
  width: 160px;
  height: 45px;
  color: #ffffff;
  border-radius: 500px;
  border: 2px solid;
  border-color: transparent;
  text-align: center;
  font-weight: bold;
  line-height: 41px;
}

.whatsappBtnImageContainer {
  display: flex;
  flex-direction: row;
  margin: 20px 0px;
  flex-wrap: wrap;

}

.whatsappDepositStepText {
  margin-right: 10px;
  margin-bottom: 20px;
}

.btn {
  position: relative;
  z-index: 20;
  font-family: "Poppins", sans-serif;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  height: 60px;
  line-height: 60px;
  font-size: 25px;
  color: #ffffff;
  border-radius: 500px;
  border: 2px solid;
  border-color: transparent;
  cursor: pointer;
  margin: 20px;
  text-align: center;
  padding: 0 20px;
  font-weight: bold;
  cursor: pointer;
}

.btn:focus {
  outline: 0;
}

.btn:active {
  transform: translate(2px, 2px);
}

.btn:first-of-type {
  margin-left: 0;
}

.btn-clear {
  background-color: transparent;
  border: 2px solid #ffffff;
}

.btn-blue-light {
  background-color: #0a9ba9;
}

.btn-purple-light {
  background-color: #743c7a;
}

.btn-purple-dark {
  background-color: #5f2f65;
}

.btn-red-light {
  background-color: #ce181e;
}

.btn-white {
  background-color: #ffffff;
  color: #047b8d;
}

.btn-close {
  cursor: pointer;
  position: absolute;
  z-index: 100;
}

.btn-blue-clear {
  background-color: transparent;
  color: #0a9ba9;
}

.btn-nav {
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  font-weight: bold;
}

.btn.disabled {
  background-color: #747d8c;
}

.btn.disabled:active {
  transform: translate(0px, 0px);
}

.cursor {
  cursor: pointer;
}

.bold {
  font-weight: bold !important;
}

body,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
nav,
ul,
ol,
li,
span,
div,
button,
form,
img {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

.divider {
  width: 50%;
  color: #6d6e70;
  height: 1px;
  margin: 50px auto;
}

.underline {
  text-decoration: underline;
}


.bg-top {
  height: 80px;
  width: 100%;
  clip-path: polygon(0 80px, 100% 0, 100% 80px, 0 80px);
  position: relative;
  z-index: 1;
  top: -79px;
}

.shareUpsellMainContainer {
  background-image: url("https://cms.computicket.com/api/assets/mma/b89b202e-d07b-48cc-9d11-495ef165bf02/rectangle-789-1-.png?version=0");
  margin-bottom: 40px;
}


.shareUpselContentContainerMain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 770px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;


}

.shareUpselImageContainer img {
  width: 250px;
  height: 250px;
}

.shareUpselContentContainer {
  margin-left: 30px;
  ;
}

.shareUpsellContentHeader {
  font-size: 30px;
  font-family: "Fredoka One", cursive;
  color: #2F3542;
  margin-bottom: 15px;
}

.shareUpsellContentBody {
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  color: #2F3542;
  margin-bottom: 20px;
}

.shareUpsellContentButton {
  display: block;
  background-color: #ED1C24;
  color: #ffffff;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  width: 140px;
  height: 40px;
  color: #ffffff;
  border-radius: 500px;
  border: 2px solid;
  border-color: transparent;
  text-align: center;
  font-weight: bold;
  line-height: 36px;
}

.shareUpsellContentButtonMobile {
  display: none;

}

@media (max-width: 845px) {
  .tabBottomTextContainer {
    display: flex;
    flex-direction: column;
  }

  .tabBottomText {
    font-size: 12px;
    text-align: center;
    color: #047B8d;
    font-weight: bold;
    margin-right: 10px;
  }

  .bottomeStoreImage {
    margin-right: 5px;
    height: 26px;
  }

  .tabBottomAppIconContainer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .shareUpsellMainContainer {
    padding-top: 25px;
    padding-bottom: 20px;
    padding-right: 10px !important;
    padding-left: 10px !important;

  }

  .freeDataDivContainer {
    text-align: center;
    margin-bottom: 40px;
  }

  .freeDataDivContainer img {
    max-width: 450px;
  }


  .shareUpselContentContainerMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap-reverse;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;

  }

  .shareUpselImageContainer img {
    width: 250px;
    height: 250px;
  }

  .shareUpselContentContainer {
    margin-left: auto;
    margin-right: auto;

  }

  .shareUpsellContentHeader {
    font-size: 28px;
    font-family: "Fredoka One", cursive;
    color: #2F3542;
    margin-bottom: 20px;
  }

  .shareUpsellContentBody {
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    color: #2F3542;
    margin-bottom: 20px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .shareUpsellContentButton {
    display: none;
  }

  .shareUpsellContentButtonMobile {
    margin: 0px auto;
    display: block;
    background-color: #ED1C24;
    color: #ffffff;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    width: 140px;
    height: 40px;
    color: #ffffff;
    border-radius: 500px;
    border: 2px solid;
    border-color: transparent;
    text-align: center;
    font-weight: bold;
    line-height: 36px;
    margin-bottom: 40px;

  }
}


.switchTabSubHeader {
  text-align: center;
  color: white;
  font-size: 20px;
  width: 80%;
  margin: auto;
}

.stepsPhoneImageWhatsapp {
  text-align: center;
}

.boxes {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}

.sasaNumHead {
  color: #57606F;
  font-family: "Fredoka One", cursive;
  font-size: 18px;
  margin-bottom: 10px;
  ;
}

.sasaNumText {}

.sassaStepsMainContainer {
  display: flex;
  flex-wrap: wrap;


}

.switchTabMain {
  text-align: center;
}

.sassaStepContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 15px 20px;
  width: 55px
}

.sassaStepImage {
  width: 45px;
  ;
}

.sassaStepText {
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  ;
}

.box {
  padding: 10px 100px;
}

.box img {
  width: 100px;
}

.box:first-of-type {
  border-right: 2px solid #ffffff;
}

.contact-a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 5px 0;
  font-size: 18px;
}


.contact2-a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  font-size: 18px;
}

.switchStep img {
  max-height: 46px;
}

.stepsPhoneContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.XtraSavingsStepsPhoneContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.switchIsEasyHeader {
  margin-bottom: 60px !important;
}

.stepsPhoneHeader {
  text-align: center;
}

.XtraSavingsStepsPhoneHeader {
  text-align: center;
}

.XtraSavingsStepsPhoneHeader img {
  width: 45px;
}

.XtraSavingsStepsDownloadAppContainerImageContainer {
  display: flex;
  justify-content: center;
}

.XtraSavingsStepsDownloadAppContainerImageContainer img {
  width: 129px;
  margin: 0px 10px;
  cursor: pointer;
}

.XtraSavingsStepsDownloadAppContainerText {
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}


.blackBold {
  font-weight: bold;
  color: #131E29 !important;
}

.XtraSavingsStepsPhoneHeaderText {
  font-size: 14px;
  margin: 10px 0px;
  font-weight: bold;
  /* color: #131E29; */
}

.stepsPhoneHeaderText {
  font-size: 14px;
  margin: 10px 0px;
}

.stepsPhoneHeaderWhatsapp {
  text-align: center;
}

.stepsPhoneHeaderTextWhatsapp {
  font-size: 14px;
  margin: 10px 0px;
}

.stepsPhoneItemContainer {
  margin: 20px 20px;
}

.XtraSavingsStepsPhoneItemContainer {
  margin: 20px 20px;
  min-width: 185px;
}

.XtrasavingsStepsPhoneImage img {
  width: 177px;
}

.stepsPhoneItemContainerWhatsapp {
  margin: 20px 20px;
}

.switchCardMainContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

.whatsAppstepsIcon {
  width: 180px;
}

.whatsAppstepsPhoneHeaderText {
  font-size: 20px;
  margin: 20px 0px;

}

.switchCardContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: 7px 4px 12px 3px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  color: #57606F;
  font-family: "Fredoka One", cursive;
  height: 370px;
  width: 360px;
  margin: 40px 10px;

}

.cardHeading {
  font-size: 30px;
  flex: 30%;
  padding: 30px 40px 0px 40px;
  /* margin: auto; */
}

.cardIcon {
  flex: 30%;

}

.cardText {
  flex: 33%;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  padding: 0px 30px 0px 30px;


}

.switchEasyContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

.switchStepText {
  font-size: 16px;
  margin-left: 15px;
  text-align: left;
}

.switchEasyLeftContainer {
  flex: 45%;
  text-align: center;
  min-width: 340px;
}

.img-container-mobile-header {
  font-size: 22px;
}

@media (max-width: 845px) {
  .switcCeneteContainer {
    display: none !important;
  }

  .switchEasyLeftContainer {
    flex: 100%;

  }

  .switchEasyRightContainer {
    flex: 100%;

  }


}

.deals-info-image img {
  width: 335px;
}

@media (max-width: 500px) {
  .deals-info-image img {
    width: 280px;
  }

  .bank-security-container-phone-image {
    width: 280px;
  }

}

@media (max-width: 600px) {

  .freeDataDivContainer {
    text-align: center;
    margin-bottom: 40px;
  }

  .freeDataDivContainer img {
    max-width: 320px;
  }


  .switchTabInsideHeader h2 {
    font-size: 20px;
  }

  .swithTabButtonMainContainer {
    margin-bottom: -25px;
    ;
  }

  .swithTabButton {
    border-radius: 50px;
    color: white;
    font-size: 20px;
    background-color: transparent;
    border: 1px solid white;
    font-weight: bold;
    max-width: 100px !important;
    text-align: center;
    margin: 0px 5px !important;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .stepsPhoneItemContainer {
    max-width: 150px;
  }

  .XtraSavingsStepsPhoneItemContainer {
    min-width: unset;
    min-width: 135px;
  }


  .whatsapBrandLogosComboImg {
    width: 300px;
  }

  .how-to-use-section-img {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
  }

  .stepsPhoneItemContainerWhatsapp {
    /* max-width: 150px; */
  }


  .stepsPhoneImage img {
    width: 150px;
  }

  .XtrasavingsStepsPhoneImage img {
    width: 150px;
  }

  .XtraSavingsStepsPhoneHeader img {
    width: 31px;
  }

  .XtraSavingsStepsDownloadAppContainerImageContainer {
    display: flex;
  }

  .XtraSavingsStepsDownloadAppContainerImageContainer img {}

  .XtraSavingsStepsDownloadAppContainerText {
    font-size: 12px;
    font-weight: bold;
  }

  .XtraSavingsStepsPhoneHeaderText {
    font-size: 12px;

  }

  .stepsPhoneImageWhatsapp img {

    width: 250px;
  }

  /* .business-slider-slider {
    width: 100% !important;
  } */

  .bsliderCustom5::before {
    left: -50% !important;
  }
}

@media (max-width: 450px) {
  .stepsPhoneItemContainer {
    max-width: 120px;
  }

  .XtraSavingsStepsPhoneItemContainer {
    min-width: unset;
    max-width: 135px;
  }

  .stepsPhoneItemContainerWhatsapp {
    /* max-width: 120px; */
  }

  .stepsPhoneImage img {
    width: 120px;
  }

  .XtrasavingsStepsPhoneImage img {
    width: 120px;
  }

  .XtraSavingsStepsPhoneHeader img {
    width: 31px;
  }



  .XtraSavingsStepsDownloadAppContainerImageContainer {
    display: flex;
  }

  .XtraSavingsStepsDownloadAppContainerImageContainer img {}

  .XtraSavingsStepsDownloadAppContainerText {
    font-size: 12px;
  }

  .XtraSavingsStepsPhoneHeaderText {
    font-size: 12px;

  }

  .stepsPhoneImageWhatsapp img {
    width: 200px;
  }

  .stepsPhoneHeader {
    min-height: 125px;
  }

  .XtraSavingsStepsPhoneHeader {
    min-height: 100px;
  }

  .stepsPhoneHeaderWhatsapp {
    min-height: 125px;
  }


  .xtra-questions-header {
    text-align: left;
    padding: 0px;
  }

  .xtra-questions-sub-header {
    text-align: left;
  }

  .switchCardContainer {
    margin: 10px auto;
    width: 335px;
  }

  .hLine {
    display: block !important;
    background: #F5F5F5;
    height: 1px;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

.switcCeneteContainer {
  flex: 10%;
  display: flex;
  justify-content: center;

}

.hLine {
  display: none;
}

.switchEasyRightContainer {
  flex: 45%;
  text-align: center;
  min-width: 340px;

}

.switchVl {
  border-left: 2px solid #F5F5F5;
  height: 500px;
}

.leftSwitchHeader {
  color: white;
  font-size: 35px;
  font-family: "Fredoka One", cursive;
}

.leftSwitchHeader span {
  background-color: #CE181E;
  padding: 5px 10px;
}

.rightSwitchHeader {
  color: white;
  font-size: 35px;
  font-family: "Fredoka One", cursive;

}

.rightSwitchHeader span {
  background-color: #047B8D;
  padding: 5px 10px;

}

.switchStepsContainer {}

.switchStep {
  display: flex;
  margin: 40px 0px;
}

.contact-a img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.active-link {
  color: #0a9ba9;
  font-weight: bold;
}

.text-red-dark {
  color: #ce181e !important;
}

.text-red-light {
  color: #ed1c24 !important;
}

.text-purple-dark {
  color: #5f2f65 !important;
}

.text-blue-light {
  color: #0a9ba9 !important;
}

.text-blue-dark {
  color: #047b8d !important;
}

.spinner {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shareCardContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 40px 0px;
  width: 90%;
  max-width: 950px;
  margin-top: 20px;
}


.shareGreyOutContainer {
  position: relative;
}

.shareGreyOut {
  background: #f1f2f6;
  opacity: 0.8;
  display: block;
  z-index: 99;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dealsStepsContainerMain {
  margin-top: 60px;
}

.deals-step-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.dealStep {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 0px 20px;
}

.dealStep img {
  width: 46px;
  margin-bottom: 10px;
}

.dealStep .text {
  font-size: 16px;

}

.dealStep .link {
  font-size: 16px;
  text-decoration: underline;
  font-weight: bold;
}



.referraltcs {
  width: 100%;
  text-align: center;
  /* margin: 50px auto -50px; */
  margin-bottom: 20px;
  font-weight: bold;
  text-decoration: underline;
  display: block;
  font-size: 16px;
}

.referraltcsDetail {
  padding: 0px 50px;
  text-align: center;
  margin: 20px auto 0px auto;
}

.accordion:active,
.accordion:focus {
  outline: none;
}

.accordion>*:active,
.accordion>*:focus {
  outline: none;
}

.accordion>*>*:active,
.accordion>*>*:focus {
  outline: none;
}

.accordion>*>*>*:active,
.accordion>*>*>*:focus {
  outline: none;
}

.accordion>*>*>*>*:active,
.accordion>*>*>*>*:focus {
  outline: none;
}

.accordion>*>*>*>*>*:active,
.accordion>*>*>*>*>*:focus {
  outline: none;
}

.accordion>*>*>*>*>*>*:active,
.accordion>*>*>*>*>*>*:focus {
  outline: none;
}

.alice-carousel__stage {
  transition-timing-function: linear !important;
}

.alice-carousel__stage li {
  margin: 0 20px;
}

.no-break {
  word-break: keep-all;
  white-space: nowrap;
}

.spacer {
  height: 50px;
}

.startChatNowBtn {
  text-align: center;
}

.top-tip {
  margin: 20px auto 50px;
  background: #0a9ba9;
  padding: 20px;
  padding-left: 120px;
  max-width: 800px;
  position: relative;
  color: #ffffff;
}

.top-tip>p {
  color: #ffffff;
  text-align: left !important;
  margin: 10px 0 !important;
  padding: 0;
}

.top-tip img {
  position: absolute;
  left: -160px;
  top: -30px;
}

.top-tip .large {
  font-size: 38px;
}

.top-tip a {
  font-size: 20px;
  font-weight: bold;
}

@media (min-width: 1000px) {

  .about,
  .for-business,
  .for-people,
  .help,
  .not-found,
  .how-to-use,
  .where-to-use,
  .link-card,
  .register,
  .security,
  .banking,
  .link-card,
  .login,
  .promo,
  .register-end {
    margin-top: 120px;
  }

  .agent {
    margin-top: 0 !important;
  }
}

@media (max-width: 450px) {
  .sassaStepContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: 15px 8px;
    width: 55px
  }
}

@media (max-width: 1200px) {

  .switchStepTabBtnContainer {
    flex-direction: column-reverse;
  }

  .switchStepTabBtnItem {
    flex: 100% !important;

  }
}

@media (max-width: 1000px) {

  embedded-content .about,
  .embedded-content .for-business,
  .embedded-content .for-people,
  .embedded-content .help,
  .embedded-content .not-found,
  .embedded-content .how-to-use,
  .embedded-content .where-to-use,
  .embedded-content .link-card,
  .embedded-content .register,
  .embedded-content .security,
  .embedded-content .banking,
  .embedded-content .promo,
  .embedded-content .register-end {
    margin-top: 0 !important;
  }

  .about,
  .for-business,
  .for-people,
  .help,
  .not-found,
  .how-to-use,
  .where-to-use,
  .link-card,
  .register,
  .security,
  .banking,
  .link-card,
  .promo,
  .register-end {
    margin-top: 120px;
  }

  .otpPatient {
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
  }

  .referraltcsDetail {
    padding: 0px 30px;
    text-align: center;
    margin: 20px auto 0px auto;
  }

  .share-asset-space {
    height: 60px !important;
  }

  .whatsappBtnImageCTk {
    margin-top: 15px;
  }

  .whatsappBtnImageOzo {
    margin-left: 5px;
    margin-top: 10px;

  }

  .how-to-use-section-img-disaply-none {
    display: none;
  }

  .agent {
    margin-top: 0 !important;
  }

  .switchHeader {
    margin-top: 80px !important;
  }

  .switchIsEasyHeader {}
}



.app-tiles {
  margin: 80px auto 0;
  background-color: #0a9ba9;
  color: #454f5b;
  padding: 80px 0;
}

.app-tiles h2 {
  color: #454f5b;
  margin: 0 auto;
}

.app-tiles h4 {
  margin-top: 10px;
}

.app-tiles p {
  text-align: center;
  margin: 20px auto;
}

.app-tiles-container {
  width: clamp(300px, 90%, 1800px);
  margin: 80px auto 0 auto;
}

.app-tiles-selectors {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.app-tiles-tab-container {
  max-width: 1200px;
  margin: 0 auto;
}

.app-tiles .tab-tile {
  min-width: 200px;
  height: 200px;
  flex: 1;
  background: #0a9ba9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  transition: all 0.2s ease;
  cursor: pointer;
}

.app-tiles .tab-tile:hover {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
}

.app-tiles .tab-tile:hover p {
  color: #047b8d;
}

.app-tiles .tab-tile:hover~.arrow {
  border-top-color: #ffffff;
}

.app-tiles .tab-tile p {
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
}

.app-tiles .tab-content {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
}

.app-tiles .tab-content .main {
  font-weight: bold;
  color: #047b8d;
}

.app-tiles .tab-content .block {
  min-width: 60%;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  background: #a4b0be;
  border-radius: 10px;
  min-height: 70px;
  margin: 20px 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-tiles .tab-content .img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 60%;
  padding: 20px 0;
}

.app-tiles .tab-content .img-container>* {
  margin: 10px;
}

.app-tiles .tab-content .sub {
  margin-right: 0;
  margin-bottom: -10px;
  text-align: right;
  color: #57606f;
  font-weight: light;
  max-width: unset;
}

.app-tiles .tab-content .ussd {
  font-weight: bold;
  font-size: 70px;
  opacity: 0.8;
}

.app-tiles .tab-content .tel {
  font-weight: bold;
  font-size: 50px;
  opacity: 0.8;
}

.app-tiles .tab-content .split {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.app-tiles .tab-content .split>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 40px 20px;
  margin-bottom: 20px;
}

.app-tiles .tab-content .split>div:first-of-type {
  border-right: 1px solid #6d6e70;
}

.app-tiles .react-tabs__tab-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.app-tiles .react-tabs__tab--selected>.arrow {
  width: 0;
  height: 0;
  margin: -10px auto 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #0a9ba9;
  transition: border-top-color 0.2s ease;
}

.link-card-tiles {
  margin: 80px auto 0;
  background-color: #5f2f65;
  color: #ffffff;
  padding: 80px 0;
}

.link-card-tiles h2 {
  color: #ffffff;
  margin: 0 auto;
}

.link-card-tiles h4 {
  margin-top: 10px;
  color: #454f5b;
}

.link-card-tiles p {
  text-align: center;
  margin: 20px auto;
  color: #ffffff;
}

.link-card-tiles-container {
  margin: 0 auto;
}

.link-card-tiles-tab-container {
  margin: 0 auto;
  padding: 0 100px;
}

.link-card-tiles-selectors {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.link-card-tiles .tab-tile {
  min-width: 200px;
  height: 200px;
  background: #743c7a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  transition: all 0.2s ease;
}

.link-card-tiles .tab-tile:hover {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
}

.link-card-tiles .tab-tile:hover p {
  color: #5f2f65;
}

.link-card-tiles .tab-tile:hover~.arrow {
  border-top-color: #ffffff;
}

.link-card-tiles .tab-tile p {
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
}

.link-card-tiles .tab-content {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 40px 20px;
  position: relative;
  background-color: #ffffff;
}

.link-card-tiles .tab-content p {
  color: #454f5b;
}

.link-card-tiles .tab-content .main {
  font-weight: bold;
  color: #5f2f65;
}

.link-card-tiles .tab-content .step-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.link-card-tiles .tab-content .step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.link-card-tiles .tab-content .step .text {
  margin: 0 20px 20px;
}

.link-card-tiles .tab-content .step .sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link-card-tiles .tab-content .step .sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sub-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  margin-right: 20px;
  margin-left: 20px;
}



.subImg {
  width: 96px;
}

.link-card-tiles .tab-content .step .sub a {
  margin: 10px 0;
  color: #454f5b;
  font-size: 16px;
}

.link-card-tiles .tab-content .step .sub img {
  max-height: 14px;
}

.link-card-tiles .react-tabs__tab-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.link-card-tiles .react-tabs__tab--selected>.arrow {
  width: 0;
  height: 0;
  margin: -10px auto 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #743c7a;
  transition: border-top-color 0.2s ease;
}

.nav__nav-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  height: 120px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  transition: all 300ms ease-in-out;
}

@media only screen and (max-width: 1000px) {
  .nav__nav-wrapper {
    top: 40px;
  }
}

.nav-wrapper {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}

.hamburger-button-wrapper {
  height: 30px;
  width: 30px;
  position: relative;
  font-size: 12px;
  display: none;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .hamburger-button-wrapper {
    display: block;
  }
}

.hamburger-button-wrapper:after {
  content: '';
  display: block;
  position: absolute;
  height: 150%;
  width: 150%;
  top: -25%;
  left: -25%;
}

.hamburger-button-lines {
  top: 50%;
  margin-top: 0px;
}

.hamburger-button-lines,
.hamburger-button-lines:after,
.hamburger-button-lines:before {
  height: 2px;
  pointer-events: none;
  display: block;
  content: '';
  width: 100%;
  background-color: black;
  position: absolute;
}

.hamburger-button-lines:after {
  top: -8px;
}

.hamburger-button-lines:before {
  top: 8px;
}

.nav-logo {
  max-width: 80vw;
}

.nav-items {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.nav-items:last-child {
  padding-right: 0;
}

@media only screen and (max-width: 1000px) {
  .nav-items {
    margin-top: 0;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 60vw;
    flex-direction: column;
    z-index: 1000;
    box-shadow: 4px 0px 30px 10px rgba(0, 0, 0, 0.4);
    background-color: #ffffff;
    transition: 0.2s ease-out;
    transform: translateX(100%);
  }
}

.nav-item {
  cursor: pointer;
  position: relative;
  padding: 10px 10px;
  font-size: 16px;
  color: #2f3542;
  margin: 0 auto;
}

@media only screen and (max-width: 1000px) {
  .nav-item {
    border-bottom: 1px solid #8e98a7;
    width: 90%;
    padding: 10px;
  }
}

.nav-item:hover {
  text-decoration: underline !important;
}

@media only screen and (max-width: 1000px) {
  .nav-item:hover {
    text-decoration: none !important;
  }

  nav .navbar-sub-list li {
    height: fit-content;
  }
}

nav .nav-button-right {
  min-width: 155px;
  margin: 0;
}

nav img {
  max-width: 90%;
  max-height: 70px;
}

nav a {
  color: #454f5b;
}

nav li:last-child {
  padding-right: 0;
  border: none;
  margin: 0 auto;
  border: none;
}

nav li:nth-last-child(2) {
  border: none;
}

nav .navbar-sub-list {
  position: absolute;
  top: 45px;
  left: 10px;
  width: max-content;
  z-index: 300;
  background-color: #ffffff;
}

nav .navbar-sub-list>* {
  z-index: 300;
}

nav .navbar-sub-list li a {
  padding: 10px;
  color: #454f5b;
  font-weight: 400;
  font-size: 16px;
  display: block;
  height: 100%;
  width: 100%;
  min-width: 110px;
}

nav .navbar-sub-list li:hover {
  background-color: #0a9ba9;
}

nav .navbar-sub-list li:hover a {
  color: #ffffff !important;
}

nav .nav-logo-wrapper {
  position: relative;
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
}

nav .shoprite-desktop {
  position: absolute;
  top: 5px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

nav .shoprite-desktop img {
  margin: 5px;
}

@media (max-width: 1000px) {
  nav .shoprite-desktop {
    display: none;
  }

}

nav .shoprite-mobile {
  position: absolute;
  top: -40px;
  right: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: #2f3542;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 10px;
}

nav .shoprite-mobile img {
  margin: 5px;
}

@media (min-width: 1001px) {
  nav .shoprite-mobile {
    display: none;
  }


}

nav .btn-close {
  right: 10px;
  top: 10px;
}

nav .nav-logo-left {
  min-width: 150px;
}

nav .nav-btn-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

nav .nav-btn-container>* {
  padding: 0;
  margin: 5px;
}

.bank-security {
  padding-top: 50px;
}

.bank-security-container {
  margin: 0 auto;
  width: clamp(300px, 90%, 1800px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bank-security-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 600px;
  margin: 50px 0;
}

.bank-security-box h6 {
  font-size: 18px;
  margin-bottom: 5px;
}

.bank-security-box p {
  font-size: 16px;
}

.bank-security-box-image {
  margin: 10px;
}

.bank-security-flex-gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin: 50px;
  max-width: 600px;
}

.bank-security-flex-gallery>* {
  margin: 10px;
  max-width: 180px;
  max-height: 50px;
}

.bank-security .top-tip {
  margin: 50px auto;
}

.business-showcase {
  width: 100%;
  background-color: #0a9ba9;
  overflow: hidden;
}

.business-showcase-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: -100px;
  position: relative;
}

.business-showcase-left {
  margin-left: 20%;
  margin-top: 80px;
  z-index: 10;
  position: relative;
}

.business-showcase-right {
  position: absolute;
  top: -17px;
  right: -7%;
}

.business-showcase-right img {
  max-height: 630px;
}

.business-showcase-color-text .text-row {
  background-color: #5f2f65;
  text-align: left;
  color: #ffffff;
  margin: 10px 0;
  width: fit-content;
  padding: 10px 10px 5px;
  white-space: nowrap;
  font-size: 40px;
}

.business-showcase-color-text .text-row-3rd {
  background-color: #743c7a;
}

.business-showcase p {
  font-weight: 300;
  font-size: 34px;
  color: #ffffff;
  margin: 30px 0 10px;
}

.bg-bot {
  height: 80px;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 0 80px, 0 0);
  position: relative;
  z-index: -1;
  bottom: -79px;
}

.business-showcase .btn-purple-dark {
  margin-left: 0;
}

.business-showcase .bg-bot {
  background-color: #0a9ba9;
  height: 100px;
  clip-path: polygon(0 0, 100% 0, 0 100px, 0 0);
  bottom: -99px;
}

.business-showcase .bg-top {
  top: 1px;
  background-color: #fff;
}

.business-slider {
  width: clamp(300px, 90%, 1800px);
  margin: 80px auto -160px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.business-slider h2 {
  margin: 20px 0;
}

.business-slider p {
  margin: 20px 0;
}

.business-slider-text {
  text-align: center;
}

.business-slider-discount {
  background-color: #ed1c24;
  padding: 5px 10px;
  color: #ffffff;
  font-family: "Fredoka One", cursive;
  font-size: 26px;
}

.business-slider-choose {
  font-weight: bold;
  font-size: 15px;
  color: #047b8d;
}

.business-slider-asterisk {
  font-size: 14px;
  color: #454f5b;
  align-self: end;
  margin-left: auto !important;
}

.business-slider-slider {
  width: 100%;
  max-width: 1200px;
  margin: 200px auto 100px;
}

.business-slider-slider #form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.business-slider-slider form {
  width: 90%;
}

.business-slider-slider form #form-title {
  margin-top: 0;
  font-weight: 400;
  text-align: center;
}

.business-slider-slider form #debt-amount-slider {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  position: relative;
  width: 100%;
  height: 50px;
  user-select: none;
}

.business-slider-slider form #debt-amount-slider::before {
  content: ' ';
  position: absolute;
  height: 10px;
  width: 100%;
  width: calc(100% * (4 / 5));
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0a9ba9;
}

.business-slider-slider form #debt-amount-slider input,
.business-slider-slider form #debt-amount-slider label {
  box-sizing: border-box;
  flex: 1;
  user-select: none;
  cursor: pointer;
}

.business-slider-slider form #debt-amount-slider label {
  display: inline-block;
  position: relative;
  width: 20%;
  height: 100%;
  user-select: none;
  color: #0a9ba9;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 1000px) {
  .business-slider-slider form #debt-amount-slider label {
    color: transparent;
  }
}

.business-slider-slider form #debt-amount-slider label .squaricle img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.business-slider-slider form #debt-amount-slider label .red-line {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  height: 80px;
  width: 6px;
  background-color: #ed1c24;
  display: block;
  transition: all 0.3s ease-in;
  z-index: -1;
}

.business-slider-slider form #debt-amount-slider label::before {
  content: attr(data-debt-amount);
  position: absolute;
  left: 50%;
  padding-top: 10px;
  transform: translate(-50%, 45px);
  font-size: 14px;
  letter-spacing: 0.4px;
  white-space: nowrap;
  opacity: 0.85;
  transition: all 0.15s ease-in-out;
  font-weight: 800;
}

.business-slider-slider form #debt-amount-slider label::after {
  content: ' ';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border: 6px solid #0a9ba9;
  background: #0a9ba9;
  border-radius: 50%;
  pointer-events: none;
  user-select: none;
  z-index: 1;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.business-slider-slider form #debt-amount-slider label:hover::after {
  transform: translate(-50%, -50%) scale(1.25);
}

.business-slider-slider form #debt-amount-slider input {
  display: none;
}

.business-slider-slider form #debt-amount-slider input:checked+label .squaricle img {
  opacity: 1;
  top: -140px;
}

.business-slider-slider form #debt-amount-slider input:checked+label .red-line {
  opacity: 1;
  top: -70px;
}

.business-slider-slider form #debt-amount-slider input:checked+label::before {
  color: #ed1c24;
  opacity: 1;
}

.business-slider-slider form #debt-amount-slider input:checked+label::after {
  border-width: 6px;
  transform: translate(-50%, -50%) scale(1);
}

.business-slider-slider form #debt-amount-slider input:checked~#debt-amount-pos {
  opacity: 1;
}

.business-slider-slider form #debt-amount-slider input:checked:nth-child(1)~#debt-amount-pos {
  left: 10%;
}

.business-slider-slider form #debt-amount-slider input:checked:nth-child(3)~#debt-amount-pos {
  left: 30%;
}

.business-slider-slider form #debt-amount-slider input:checked:nth-child(5)~#debt-amount-pos {
  left: 50%;
}

.business-slider-slider form #debt-amount-slider input:checked:nth-child(7)~#debt-amount-pos {
  left: 70%;
}

.business-slider-slider form #debt-amount-slider input:checked:nth-child(9)~#debt-amount-pos {
  left: 90%;
}

.business-slider-slider form #debt-amount-slider #debt-amount-pos {
  box-sizing: content-box;
  display: block;
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  background: #ed1c24;
  border-radius: 50%;
  transition: all 0.15s ease-in-out;
  transform: translate(-50%, -50%);
  border: 4px solid #fdc231;
  opacity: 0;
  z-index: 2;
}

.business-slider-slider form:valid #debt-amount-slider input+label::before {
  transform: translate(-50%, 45px) scale(0.9);
  transition: all 0.15s linear;
}

.business-slider-slider form:valid #debt-amount-slider input:checked+label::before {
  transform: translate(-50%, 45px) scale(1.1);
  transition: all 0.15s linear;
}

.business-slider-slider form+button {
  display: block;
  position: relative;
  margin: 56px auto 0;
  padding: 10px 20px;
  appearance: none;
  transition: all 0.15s ease-in-out;
  font-family: inherit;
  font-size: 24px;
  font-weight: 600;
  background: #fff;
  border: 2px solid #000;
  border-radius: 8px;
  outline: 0;
  user-select: none;
  cursor: pointer;
}

.business-slider-slider form+button:hover {
  background: #000;
  color: #fff;
}

.business-slider-slider form+button:hover:active {
  transform: scale(0.9);
}

.business-slider-slider form+button:focus {
  background: #4caf50;
  border-color: #4caf50;
  color: #fff;
  pointer-events: none;
}

.business-slider-slider form+button:focus::before {
  animation: spin 1s linear infinite;
}

.business-slider-slider form+button::before {
  display: inline-block;
  width: 0;
  opacity: 0;
  content: '\f3f4';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  margin-right: 0;
  transform: rotate(0deg);
}

.business-slider-slider form:invalid+button {
  pointer-events: none;
  opacity: 0.25;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }

  to {
    transform: rotate(360deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }
}

.business-steps {
  background-color: #743c7a;
  color: #ffffff;
  margin: 80px auto;
}

.business-steps-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.business-steps h2 {
  color: #ffffff;
  margin-bottom: 50px;
}

.business-steps p {
  color: #ffffff;
}

.business-steps-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
}

.business-steps-steps {
  width: 400px;
}

.business-steps-step {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 50px 0 0;
}

.business-steps-step h6 {
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.business-steps-step-left img {
  width: 60px;
  height: 60px;
}

.business-steps-step-right {
  margin-top: 20px;
  margin-left: 20px;
}

.business-steps-step-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.business-steps .bg-bot,
.business-steps .bg-top {
  background-color: #743c7a;
}

.business-tabs {
  background-color: #047b8d;
  margin: 80px auto;
}

.business-tabs-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
}

.business-tabs h2 {
  color: #ffffff;
  margin: 0 auto 50px;
}


.swithTabButtonMainContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.swithTabButton {
  border-radius: 50px;
  color: white;
  font-size: 30px;
  background-color: transparent;
  border: 1px solid white;
  font-weight: bold;
  width: 300px;
  text-align: center;
  margin: 0px 10px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}



.business-tabs-selectors {
  background-color: #ffffff;
  margin: 0 auto;
  width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 50px;
}

.business-tabs-selector {
  border-radius: 50px;
  background-color: #ffffff;
  width: 100%;
  text-align: center;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.business-tabs-selector-business {
  color: #0a9ba9;
}

.business-tabs-selector-recipients {
  color: #5f2f65;
}

.business-tabs-selector h6 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.business-tabs-selector-business.react-tabs__tab--selected {
  background-color: #0a9ba9;
  color: #ffffff;
}

.business-tabs-selector-recipients.react-tabs__tab--selected {
  background-color: #5f2f65;
  color: #ffffff;
}

.business-tabs-content {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 30px;
  padding: 50px;
  margin: 50px auto;
  color: #ffffff;
  position: relative;
  max-width: 1200px;
}

.business-tabs-content-text {
  z-index: 10;
  max-width: 600px;
  width: 100%;
}

.business-tabs-switch-content-text {
  z-index: 10;
  width: 100%;
  text-align: center;
}

.switchStepsTabItemContainerSubHead {
  font-size: 20px;
  margin-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.switchStepTabItem {
  margin-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.switchStepTabItem img {
  width: 46px;
  margin-bottom: 20px;

}

.switchStepsTabItemContainer {
  text-align: center;
}

.switchStepTabText {
  font-size: 20px;
  text-align: center;
  max-width: 450px;
  margin: auto;
}

.business-tabs-content-image {
  position: absolute;
  bottom: -10px;
  right: -100px;
}

.business-tabs-content h2 {
  width: fit-content;
  padding: 10px;
  margin: 20px 0 0;
}

.business-tabs-content .fade-text {
  color: #ffffff;
  opacity: 0.4;
  font-size: 35px;
  margin: 0;
}

.business-tabs-content .ticks {
  font-size: 20px;
  padding-left: 30px;
  width: 100%;
}

.business-tabs-content .ticks li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 0;
}

.business-tabs-content .ticks .tick-image {
  display: block;
  padding-right: 10px;
}

.business-tabs-content .ticks .tick-image img {
  height: 20px;
  width: 20px;
}

.business-tabs-content img {
  width: 100%;
}

.business-tabs-content-business {
  background-color: #0a9ba9;
}

.business-tabs-content-business h2 {
  background-color: #047b8d;
}

.business-tabs-content-recipients {
  background-color: #5f2f65;
}

.business-tabs-content-recipients h2 {
  background-color: #743c7a;
}

.business-tabs-content-srd {
  background-color: #57606F;
}

.business-tabs-content-srd h2 {
  background-color: #747D8C;
}

.business-tabs-content .btn {
  margin-left: 0;
}

.business-tabs .bg-bot,
.business-tabs .bg-top {
  background-color: #047b8d;
}

.clients {
  margin: 80px auto;
  background-color: #747d8c;
  position: relative;
  top: -3px;
  color: #ffffff;
}

.clients h2 {
  color: #ffffff;
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto 50px;
}

.clients p {
  color: #ffffff;
}

.clients-stats {
  width: clamp(300px, 90%, 1800px);
  border-radius: 100px;
  border: 4px solid #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 20px;
  width: 80%;
  margin: 50px auto 0;
}

.clients-stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clients-stat h2 {
  margin: 0;
  width: fit-content;
}

.clients .swiper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin: 0 auto;
}

.clients .swiper-container {
  width: 100%;
  max-width: 1040px;
}

.clients .swiper-button-prev,
.clients .swiper-button-next {
  color: #ffffff;
  top: 45%;
  font-weight: bold;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.clients .swiper-button-prev::after,
.clients .swiper-button-next::after {
  font-size: 35px !important;
}

.clients .swiper-button-prev {
  margin-left: -30px;
}

.clients .swiper-button-next {
  margin-right: -30px;
}

.clients .swiper-button-disabled {
  opacity: 0 !important;
}

.clients .swiper-slide {
  max-width: 260px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.swiper-wrapper {
  justify-content: center;
}

.clients .swiper-slide img {
  height: 90%;
  width: 90%;
}

.clients .btn-swiper {
  width: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding: 0 5px;
}

.clients .bg-bot,
.clients .bg-top {
  background-color: #747d8c;
}

.do-more {
  margin: 80px auto -3px;
  background-color: #0a9ba9;
  color: #ffffff;
  position: relative;
}

.do-more h2 {
  color: #ffffff;
  margin: 0 auto 40px;
}

.do-more h4 {
  margin-top: 10px;
}

.do-more-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
}

.do-more-selectors {
  background-color: #ffffff;
  margin: 0 auto;
  width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 50px;
}

.do-more-selector {
  border-radius: 50px;
  background-color: #ffffff;
  width: 100%;
  text-align: center;
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #0a9ba9;
}

.do-more-selector .small {
  color: #0a9ba9;
  font-size: 16px;
}

.do-more-selector h6 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.do-more-selector.react-tabs__tab--selected {
  background-color: #5f2f65;
  color: #ffffff;
}

.do-more-selector.react-tabs__tab--selected>.small {
  color: #ffffff;
}

.do-more .do-more-how {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.do-more .do-more-how p {
  text-align: center;
  color: #ffffff;
  margin: 50px auto;
}

.do-more .do-more-how .btn {
  margin: 50px auto 0;
}

.do-more-grid {
  display: flex;
  justify-content: center;
}

.do-more-grid-col {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  max-height: 500px;
  max-width: 330px;
}

.do-more-grid-col-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.do-more-grid-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: 0.3s ease;
  cursor: pointer;
}

.do-more-grid-icon:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.do-more-grid-icon.reverse {
  flex-direction: row-reverse;
  text-align: right;
}

.do-more-grid-icon h6 {
  font-size: 20px;
}

.do-more-grid-icon>img {
  margin: 0 10px;
}

.do-more .bg-bot,
.do-more .bg-top {
  background-color: #0a9ba9;
}

.faq-tabs-xtra {
  margin: 0px auto !important;
}

.faq-tabs {
  margin: 100px auto;
}

.faq-tabs .faq-tabs-anchor {
  position: relative;
}

.faq-tabs h2 {
  margin: 50px auto;
}

.faq-tabs p {
  margin-left: auto !important;
  margin-right: auto !important;

}

.faq-tabs-container {
  padding-top: 20px;
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.productItemsContainer {}

.productItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  max-width: 1200px;
}

.productItem {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 0 20px 40px 0;
  text-align: left;
}

.productItemimg {
  max-width: 100%;
  height: auto;
}

.discountCircle {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 80px;
  height: 80px;
}

.productNormalPrice {
  font-size: 35px;
  font-weight: bolder;
  color: black;
  line-height: 22px;
  padding-top: 10px;
  padding-bottom: 5px;
  position: relative;
}

.productPriceCents {
  font-size: 15px;
  padding: 0px;
  position: absolute;
  top: -0px;
}

.productSaveImage {
  width: 130px;
  margin-top: 20px;
}

.productSavingPrice {
  font-size: 35px;
  font-weight: bolder;
  color: #892890;
  line-height: 22px;
  position: relative;


}

.productSavingPriceCents {
  font-size: 15px;
  padding: 0px;
  position: absolute;
  top: -8px;
}

.productSavingPriceMessage {
  font-size: 13px;
  color: #892890;
  font-weight: bold;
  line-height: 10px;

}

.productDescription {
  font-size: 13px;
  color: black;
  font-weight: bold;
  padding: 20px 0px;
  height: 100px;

}

.productDate {
  color: #454F5B;

}

.xtraInfoHeader {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}



.xtraInfoHeaderBanking h2 {
  color: white;
}

.xtraInfoHeaderBanking p {
  color: white;
}

.xtraInfoFooterContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 20px;
  margin-top: 50px;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.whiteText {
  color: white !important;

}



.greenBackground {
  background-color: #0A9BA9;
  color: white !important;

}

.purpleBackground
{
  background-color: #743C7A;
  color: white !important;
}

.greyBackground {
  background-color: #F1F2F6;
}

.greyBackground {
  background-color: #F1F2F6;
}

.xtraInfoHeader h2 {
  color: #57606F;
}

.xtraInfoHeader p {
  color: #57606F;
}

.xtra-saving-new-content-container {
  margin: auto;
  font-size: 20px;
  padding-left: 20px;
  max-width: 650px;
}

.xtra-saving-title {
  color: #57606F;
}

.xtraInfoHeader h2 {
  max-width: unset;
}



.faq-tabs .search-wrapper {
  position: relative;
  width: 60%;
  margin: 0 auto;
}

.faq-tabs .search svg {
  position: absolute;
  left: 20px;
  top: 13px;
  display: block;
}

.faq-tabs .search-input {
  display: block;
  width: 100%;
  background-color: #c7ccd4;
  padding: 10px 50px;
  font-size: 20px;
  border: 0;
  border-radius: 50px;
  margin: 0 auto 50px;
}

.faq-tabs .search-input:focus,
.faq-tabs .search-input:active {
  outline: 0;
}

.faq-tabs .search-input:focus+.search-results,
.faq-tabs .search-input:active+.search-results {
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
}

.faq-tabs .search-results {
  max-height: 800px;
  transition: all 0.3s ease;
  width: 80%;
  overflow: visible;
  position: absolute;
  top: 44px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: left;
  cursor: pointer;
  box-shadow: none;
  z-index: 1000;
  overflow-y: scroll;
}

.faq-tabs .search-results-field {
  margin: 50px auto;
  position: relative;
  width: 60%;
}

.faq-tabs .search-results li {
  background-color: #c7ccd4;
  padding: 20px 50px;
  border-bottom: 1px solid #c7ccd4;
  font-size: 16px;
}

.faq-tabs-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.faq-tabs-selectors {
  background-color: #ffffff;
  margin: 0 auto;
  width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 50px;
  border: 1px solid #0a9ba9;
  margin: 50px auto;
}

.faq-tabs-selector {
  border-radius: 50px;
  background-color: #ffffff;
  width: 100%;
  text-align: center;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.faq-tabs-selector-business {
  color: #0a9ba9;
}

.faq-tabs-selector-recipients {
  color: #5f2f65;
}

.faq-tabs-selector h6 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.faq-tabs-selector-business.react-tabs__tab--selected {
  background-color: #0a9ba9;
  color: #ffffff;
}

.faq-tabs-selector-recipients.react-tabs__tab--selected {
  background-color: #5f2f65;
  color: #ffffff;
}

.faq-tabs-main {
  margin: 0 auto 20px;
  width: 80%;
}

.faq-tabs-main-heading {
  border-radius: 50px;
  color: #ffffff;
  text-align: left;
  padding: 10px 50px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
}

.displayNone {
  display: none;
}

.faq-tabs-main-business-heading {
  background-color: #0a9ba9;
}

.faq-tabs-main-recipients-heading {
  background-color: #5f2f65;
}


.faq-tabs-sub {
  width: 80%;
  margin: 0 auto;
  color: #57606f;
  text-align: left;
  border-top: 1px solid #57606f;
  padding: 20px;
}

.faq-tabs-sub:last-of-type {
  border-bottom: 1px solid #57606f;
}

.faqAnswers {
  color: #8e98a7 !important
}

.faqAnswers ul {
  margin-left: 60px;
  margin-bottom: 20px;
}

.faqAnswers ol {
  margin-left: 60px;
  margin-bottom: 20px;
}

.p1 {
  color: #8e98a7 !important
}

.p4 {
  color: #8e98a7 !important
}

.faqAnswers ul .li1 {

  margin-bottom: 10px;
}

.faq-tabs-sub-heading {
  font-size: 20px;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faq-tabs-sub-content {
  margin: 20px 0 0;
  font-size: 20px;
  color: #8e98a7;
}

.faq-tabs-sub-content ul {
  list-style-type: initial;
}

.limitsHeaderContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}

.fees-limits {
  background-color: #5f2f65;
}

.fees-limits-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fees-limits h2,
.fees-limits p {
  text-align: center;
  color: #ffffff;
}

.fees-limits-section {
  border-bottom: 1px solid #ffffff;
  padding: 50px 100px;
  color: #ffffff;
  width: 80%;
  margin: 0 auto;
}

.fees-limits-section:last-of-type {
  border-bottom: none;
}

.fees-limits-list {
  list-style-type: disc;
  padding-left: 30px;
}

.fees-limits-list>li {
  list-style-type: disc;
  font-size: 16px;
}

.fees-limits h6 {
  font-size: 18px;
  margin: 5px 0;
}

.footer {
  margin: 100px auto 0;
  background-color: #2f3542;
}

.footer-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
}

.footer h6 {
  font-family: "Fredoka One", cursive;
  font-size: 20px;
  font-weight: normal;
  color: #ffffff;
}

.footer-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
}

.footer-grid-links {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 50px;
  width: 100%;
}

.footer-grid-links h6 {
  margin-bottom: 15px;
}

.footer-grid-links a {
  color: #c7ccd4;
  font-size: 16px;
  display: flex;
  margin: 5px 0;
}

.footer-grid-links .contact-a img {
  width: 16px;
  height: 16px;
}

.footer-grid-logos {
  padding: 20px 0;
  border-top: 1px solid #444c58;
  border-bottom: 1px solid #444c58;
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-grid-internals {
  display: flex;
  color: #8e98a7;
}

.footer-grid-internals a {
  font-size: 14px;
  font-weight: 500;
  margin: 20px;
}

.footer-grid-copy p {
  color: #8e98a7;
  font-size: 12px;
}

.four-oh-four {
  margin: 100px auto;
  text-align: center;
}

.four-oh-four-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
}

.four-oh-four .massive {
  font-size: 272px;
  line-height: 300px;
  margin: 0 auto 50px;
}

.four-oh-four p,
.four-oh-four h2 {
  margin: 20px auto;
}

.about-showcase {
  width: 100%;
  background-color: #ce181e;
}

.about-showcase-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: -100px;
  position: relative;
}

.about-showcase-left {
  margin-left: 20%;
  margin-top: 80px;
  z-index: 10;
  position: relative;
}

.about-showcase-right {
  position: absolute;
  top: 30px;
  right: 15%;
}

.about-showcase-color-text {
  margin-right: 50px;
}

.about-showcase-color-text .text-row {
  background-color: #0a9ba9;
  text-align: left;
  color: #ffffff;
  margin: 10px 0;
  width: fit-content;
  padding: 10px 10px 5px;
  white-space: nowrap;
  font-size: 40px;
}

.about-showcase-color-text .text-row-3rd {
  background-color: #047b8d;
}

.about-showcase-text {
  font-weight: 300px;
  font-family: "Poppins", sans-serif;
  font-size: 34px;
  line-height: 50px;
  color: #ffffff;
  margin: 30px 0 10px;
}

.about-showcase .bg-bot {
  background-color: #ce181e;
  height: 100px;
  clip-path: polygon(0 0, 100% 0, 0 100px, 0 0);
  bottom: -99px;
}

.shareAssetsMainContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;

}

.shareAssetComponent {
  display: flex;
  flex-direction: column;
  width: 326px;
  margin: 0px 20px 20px 20px;
}

.shareAssetHeader {
  font-size: 16px;
  font-weight: bold;
  color: #57606F;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-bottom: 10px;
  ;
}

.shareAssetSubHeader {
  font-size: 12px;
  color: #57606F;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-bottom: 5px;
}

.shareAssetImage {
  margin: 0px auto;
}

.shareAssetImage img {}

.shareAssetButtonContainer {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.shareAssetButton {
  display: block;
  background-color: #743c7a;
  color: #ffffff;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  width: 140px;
  height: 40px;
  color: #ffffff;
  border-radius: 500px;
  border: 2px solid;
  border-color: transparent;
  text-align: center;
  font-weight: bold;
  line-height: 36px;
  margin: auto;
}

.how-mma-business {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
}

.how-mma-business h2 {
  max-width: 650px;
  margin: 20px 0;
}

.how-mma-business select {
  margin: 20px 0;
}

.shareSubHeader {
  text-align: center;
  font-size: 20px;
  font: normal;
  max-width: 710px;

}

.how-mma-business-lead {
  font-weight: normal;
  font-size: 25px;
  text-align: center;
  margin: 20px 0;
}

.how-mma-business-boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 40px 10% 40px;
  background: #F1F2F6;
}

.how-mma-business-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.how-mma-business .custom-select {
  width: 400px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  display: none;
}

.how-mma-business .css-yk16xz-control,
.how-mma-business .css-1pahdxg-control {
  border-radius: 50px;
  padding: 10px 20px 10px 20px;
  width: 100%;
}

.how-mma-business .css-26l3qy-menu {
  width: 80%;
  padding: 0 20px;
}

.how-mma-business .css-26l3qy-menu>*>* {
  border-bottom: 1px solid #c7ccd4;
}

.how-mma-business .css-26l3qy-menu>*>*:last-child {
  border-bottom: none;
}


.bankingItemsContainer {
  margin-top: 40px;
}

.bankingItems {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.bankingItem {
  text-align: center;
  width: 260px;
  margin: 0px 40px;
}

.bankingItemTitle {
  font-size: 18px;
  font-weight: bold;
}

.bankingItemDesc {
  font-size: 16px;
  margin-bottom: 20px;
  height: 75px;
}

.cashBackItemDesc {
  font-size: 20px;
  margin-bottom: 20px;
  height: 75px;
}

.bankingItemimg {
  width: 193px;
}

.create-code {
  margin: 80px auto 0;
  background-color: #ffffff;
  color: #454f5b;
}

.create-code-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
  position: relative;
}

.create-code .copybtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.copybtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.create-code .toast {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #eee;
  font-weight: bold;
  padding: 15px;
  font-size: 16px;
  border-radius: 4px;
  z-index: 50;
}

.create-code .share-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #f1f2f6;
  width: 100%;
  max-width: 650px;
  height: min-content;
  z-index: 50;
  opacity: 1;
  border-radius: 25px;
  padding: 20px 10px;
  transform: translate(-50%, -50%);
}

.create-code .share-modal .close-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.create-code .create-widget-container {
  max-width: 650px;
  margin: 0 auto;
  background-color: #eee;
  border-radius: 30px;
  position: relative;
}

.create-code .create-widget-container .hstack {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.create-code .create-widget-container .btn-container {
  margin: 0;
}

.create-code .create-widget-container .form-group.checkbox {
  margin: 0;
  margin-top: 50px;
}

.create-code .create-widget-container .form-group input:disabled::placeholder {
  color: white;
}

.create-code .create-widget-container .inner {
  width: 100%;
  height: 100%;
  padding: 50px;
  position: relative;
}

.create-code .create-widget-container .inner.faded::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  z-index: 20;
}

.create-code .create-widget-container h2 {
  font-size: 40px;
}

.create-code .create-widget-container p {
  text-align: center;
  margin-top: 15px;
}

.create-code .create-widget-container .indicator-wrapper {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-code .create-widget-container .form-group {
  margin: 40px 0 0;
}

.create-code .create-widget-container .form-group.checkbox label {
  font-weight: bold;
}

.create-code .create-widget-container .form-group.checkbox label a {
  margin-left: 4px;
  color: #454f5b !important;
}

.create-code .create-widget-container .error-text {
  margin: 0;
  margin-top: -30px;
  text-align: left;
  font-size: 14px;
  color: #ff0000;
}

.create-code .existing-codes {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.create-code .existing-codes h3 {
  font-size: 40px;
  text-align: center;
  width: 100%;
}

.create-code .existing-codes .share-header {
  text-align: right;
  font-weight: bold;
  font-size: 20px;
  width: 80%;
  margin: 10px auto 0;
}

.icons {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconLabelContainer {
  margin: 0px 20px;
}

.iconLabel {
  padding-top: 10px;
  font-size: 15px;
}

.create-code .share-buttons .icons {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 10px;
}

.create-code .share-buttons .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-code .share-buttons .icon p {
  font-size: 16px;
  margin-top: 6px;
}

.create-code input#promoCode.input-created {
  color: #0a9ba9 !important;
}

.create-code input#promoCode.input-created:disabled {
  background-color: #fff;
}

.create-code .existing-code {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #ced6e0;
}

.create-code .existing-code .code-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.create-code .existing-code .code-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-code .existing-code .code-wrapper.share {
  border: none;
  cursor: pointer;
  color: inherit;
  background: transparent;
}

.create-code .existing-code .code-wrapper.share:hover {
  background-color: transparent;
}

.create-code .existing-code p {
  margin: 0;
  color: #a4b0be;
  font-weight: bold;
}

.how-to-header {
  margin: 180px auto 0;
  background-color: #ffffff;
  color: #454f5b;
}

.how-to-header h2 {
  color: #454f5b;
  margin: 0 auto 80px;
}

.how-to-header h4 {
  margin-top: 10px;
}

.how-to-header p {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.how-to-header-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
  position: relative;
}

.how-to-header .btn-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #ce181e;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.how-to-header .btn-to-top svg {
  min-height: 50px;
  min-width: 50px;
}

.how-to-header-selectors {
  background-color: #ffffff;
  margin: 50px auto;
  width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 50px;
  border: 1px solid #5f2f65;
}

.how-to-header-selector {
  border-radius: 50px;
  background-color: #ffffff;
  width: 100%;
  text-align: center;
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #5f2f65;
}

.how-to-header-selector .small {
  font-size: 16px;
  color: #5f2f65;
}

.how-to-header-selector h6 {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

.how-to-header-selector.react-tabs__tab--selected {
  background-color: #5f2f65;
  color: #ffffff;
}

.how-to-header-selector.react-tabs__tab--selected>.small {
  color: #ffffff;
}

.invite-friends {
  margin: 120px auto 0;
  background-color: #ffffff;
  color: #454f5b;
}

.invite-friends-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
  position: relative;
}

.invite-friends-container h1 {
  font-size: 45px;
  text-align: center;
  max-width: 650px;
  margin: 0 auto;
  color: #57606F
}

.invite-friends-container img {
  text-align: center;

  margin: 0 auto;
}



.invite-friends-container p {
  font-size: 20px;
  text-align: center;
  max-width: 650px;
  margin: 50px auto 0;
}

.shareAndEarnSubHeader {
  font-size: 45px;
  font-family: "Fredoka One", cursive;
  background-color: #CE181E;
  color: white;
  width: fit-content;
  margin: 10px auto 5px auto;
  padding: 0px 10px;

}

.shareAndEarnSubSubHeader {
  font-size: 25px;
  color: #57606F;
  text-align: center;
  font-family: "Fredoka One", cursive;

}

.invite-friends-container .image-container {
  display: 'flex';
}

.invite-friends-container .image-container img {
  margin: 0px auto 0;
  width: 100%;
  max-width: 650px;
}

.shareAndEarnLineContainer {
  text-align: center;
  border-top: 3px solid #CED6E0;
  border-left: 3px solid #CED6E0;
  border-right: 3px solid #CED6E0;
  position: relative;
  height: 80px;
  max-width: 500px;
  margin: 65px auto 240px auto;
}

.shareAndEarnLineContainerHead {
  font-size: 30px;
  width: auto;
  display: inline-block;
  padding: 5px 10px;
  background-color: #ED1C24;
  color: white;
  font-family: "Fredoka One", cursive;
  position: absolute;
  left: 14%;
  top: -35%;

}

.shopriteRedBG {
  background-color: #ED1C24;
}

.checkersGreenBG {
  background-color: #03A8A0;

}

.shareAndEarnLineImageMainContainer {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
  justify-content: center;
  /* position: absolute;
  left: -37px;
  top: 40px; */
  width: 100%;

}

.shareAndEarnLineImageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 40px 15px;

}

.shareAndEarnLineImage {}



.shareAndEarnLineText {
  color: #57606F;
  text-align: center;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px auto 0px auto;
  font-family: "Poppins", sans-serif;
}

.invite-friends-container .icons {
  margin: 40px auto 40px auto;
  width: 80%;
  max-width: 650px;
}

.invite-friends-container .icons img {
  width: 100%;
  height: 100%;
}


@media (max-width: 660px) {

  .business-tabs-content .ticks {
    font-size: 16px;
  }

  .xtraInfoFooterContainer {
    font-size: 16px;
    margin-top: 10px;
  }

  .xtraInfoFooterContainerItem1 {}

  .xtraInfoFooterContainerItem2 {}

  .cashBackItemDesc {
    font-size: 16px;
    height: unset;
  }

  .xtra-saving-new-content-container {
    margin-top: 40px;
    padding-left: 0px;
  }

  .btn-share-xtra-link-btn-container {
    display: flex;
    justify-content: center;
  }

  .deals-info-image img {
    /* width: 335px; */
    display: none;
  }

  .deals-info-title {
    font-size: 25px !important;
  }

  .deals-info-content-text {
    font-size: 16px !important;
  }

  .deals-info-content-text-space {
    font-size: 16px !important;

  }

  .customRemoveBotMargin {
    margin-bottom: -80px;
  }

  .bankingItemimg {
    width: 163px;
  }

  .bankingItemimgCustom {
    width: 163px;
    height: 163px;
  }

  .dealStep h4 {
    font-size: 18px;
  }

  .dealStep .text {
    font-size: 14px !important;
  }

  .dealStep .link {
    font-size: 16px !important;
  }

  .dealStep {
    margin: 0px 0px;
  }

  .share-copy {
    padding: 0 10px;
  }

  .shareCardContainer {
    padding: 40px 0px 20px 0px;
  }

  .icons {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .iconLabelContainer {
    margin: 0px 10px 20px 10px;
    width: 75px;
  }

  .productItemsContainer {}

  .productItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .productItem {
    width: 155px;
    margin: 0px 5px 20px 5px;
    border-radius: 5.5px;
    border: 1.1px solid var(--core-sky-base-dfe-3-e-8, #DFE3E8);
    background: var(--core-white-ffffff, #FFF);
    padding: 15px;
  }

  .productItemimg {
    /* width: 143px; */
    height: 140px;
  }

  .productNormalPrice {
    font-size: 35px;
    font-weight: bolder;
    color: black;
    line-height: 22px;
    padding-top: 10px;
    padding-bottom: 5px;
    position: relative;
  }

  .productPriceCents {
    font-size: 15px;
    padding: 0px;
    position: absolute;
    top: -0px;
  }

  .productSaveImage {
    width: 76px;
  }

  .productSavingPrice {
    font-size: 35px;
    font-weight: bolder;
    color: #892890;
    line-height: 22px;
    position: relative;


  }

  .productSavingPriceCents {
    font-size: 15px;
    padding: 0px;
    position: absolute;
    top: -8px;
  }

  .productSavingPriceMessage {
    font-size: 13px;
    color: #892890;
    font-weight: bold;

  }

  .productDescription {
    font-size: 13px;
    color: black;
    font-weight: bold;
    padding: 10px 0px;
    height: 130px;

  }

  .productDate {
    color: #454F5B;

  }

  .bankingItemsContainer {
    margin-top: 20px;
  }

  .bankingItem {
    margin-bottom: 40px;
  }

  .bankingItemDesc {
    height: unset;
  }


  .sub-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .xtra-link-mobile {
    margin-top: 15px;
    margin-bottom: 40px;
  }

  .invite-friends-container .icons {
    width: 100%
  }

  .shareAndEarnLineContainer {
    margin: 55px auto 200px auto;
  }

  .shareAndEarnLineText {
    font-size: 16px;
    ;
  }

  .shareAndEarnLineContainer {
    width: 300px;
  }

  .invite-friends-container img {
    width: 120px;
  }

  .shareAndEarnLineContainerHead {
    font-size: 20px;
    left: 8%;
    top: -27%;
  }

  .shareAndEarnSubSubHeader {
    font-size: 20px;
  }

  .shareAndEarnSubHeader {
    font-size: 32px;

  }

  .invite-friends h1 {
    font-size: 32px;
  }

  .faqAnswers {
    color: #8e98a7 !important
  }

  .faqAnswers ul {
    margin-left: 10px !important;
    margin-bottom: 15px !important;
  }

  .faqAnswers ol {
    margin-left: 10px !important;
    margin-bottom: 15px !important;

  }

  .faqAnswers ul .li1 {

    margin-bottom: 10px;
  }
}

.largest-retailer {
  text-align: center;
  width: clamp(300px, 90%, 1800px);
  margin: 200px auto 0;
  padding-bottom: 50px;
}

.largest-retailer h2 {
  margin: 50px auto 0;
}

.largest-retailer p {
  margin: 20px auto 0;
}

.largest-retailer .img-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.largest-retailer .img-container a {
  height: 35px;
  margin: 30px;
}

.largest-retailer .img-container img {
  height: 100%;
}

.launch-offer {
  background-color: #ce181e;
}

.launch-offer-container {
  margin: 0 auto;
  width: clamp(300px, 90%, 1800px);
  padding: 100px 0;
}

.launch-offer-split {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.launch-offer-split-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
}

.launch-offer h2 {
  color: #ffffff;
}

.launch-offer-purple {
  background-color: #5f2f65;
  padding: 10px;
  color: #ffffff;
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.launch-offer-purple h2 {
  font-size: 112px;
  line-height: 120px;
}

.launch-offer-yellow {
  background-color: #fdc231;
  border-radius: 50%;
  padding: 20px;
  width: 250px;
  height: 250px;
  font-size: 60px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.launch-offer-yellow h2 {
  font-size: 60px;
  line-height: 70px;
}

.launch-offer-plain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.launch-offer-plain h2 {
  font-size: 60px;
  line-height: 70px;
}

.slider-container {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px 0;
  margin-bottom: 10px;
  position: relative;
  background: white;
  display: flex;
  overflow: hidden;
}

.slider-sliding {
  display: flex;
  animation: slideshow 20s linear infinite;
  align-items: center;
}

/* .slider-brand-image
{
  height: 100%;
} */


.slider-item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  margin: 0 20px;
}

@keyframes slideshow {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.link-card-promo {
  margin: 80px auto 0;
  background-color: #ffffff;
  color: #454f5b;
  padding: 80px 0;
}

.link-card-promo-card-img {
  max-width: 800px;
}

.link-card-promo h2 {
  color: #454f5b;
  margin: 20px auto 0;
}

.link-card-promo h4 {
  margin-top: 10px;
}

.link-card-promo p {
  text-align: center;
  margin: 20px auto;
}

.link-card-promo-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link-card-promo .hr-line {
  background: #747d8c;
  height: 1px;
  width: 80%;
  margin: 50px auto;
}

.link-card-promo .icon-boxes {
  margin: 50px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  width: 80%;
}

.link-card-promo .icon-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.link-card-promo .login-link {
  font-weight: bold;
  text-decoration: underline;
}

.link-card-promo .how-split {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 50px;
}

.link-card-promo .how-split-left {
  width: 40%;
}

.link-card-promo .how-split-left img {
  width: 100%;
}

.link-card-promo .how-split-right {
  width: 60%;
}

.link-card-promo .how-split-right h3 {
  font-size: 30px;
  font-weight: bold;
}

.link-card-promo .how-split-right p {
  text-align: left;
  font-size: 25px;
}

.mma-basics {
  margin: 80px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: clamp(300px, 90%, 1800px);
}



.mma-basics h3 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
}

.mma-basics p {
  margin-top: 50px;
  text-align: center;
  width: 80%;
}

.mma-basics-features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 50px;
}

.mma-basics-feature {
  flex: 1;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.mma-basics-feature p {
  margin-top: 10px;
}

.mma-basics .img-container {
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.whatsapp-basics {
  margin: 80px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.whatsapp-basics-features {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  flex-wrap: wrap;
  margin-bottom: 60px;

}

.whatsapp-basics-feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 315px;
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;


}

.whatsapp-Basic-Image {
  padding: 10px 0px;
  ;
}

.whatsapp-Basic-Header {
  height: 40px;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;

}

.whatsapp-Basic-Body {
  height: 130px;
  margin-bottom: 5px;

}

.whatsapp-Basic-Link {
  font-size: 20px;
  ;
  font-weight: bold;
}

.mma-summary {
  /* margin: -100px auto 0; */
}

.mma-summary-container {
  display: flex;
  width: clamp(300px, 90%, 1800px);
  margin: -0px auto 0;
  position: relative;
  z-index: 10;
  justify-content: center;
}

.mma-summary p {
  margin-top: 20px;
}

.mma-summary-left {
  margin-top: 150px;

}

.mma-summary-left img {
  width: 100%;
  max-width: 650px;
}

.mma-summary-right {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 50px;
  max-width: 600px;
}

.mma-summary .bg-top {
  background-color: #ffffff;
  clip-path: polygon(0% 100px, 100% 0%, 100% 100px, 0% 100px);
  top: 61px;
}

.people-cycle {
  margin: 50px auto 100px;
  background-color: #ffffff;
  z-index: 10;
  position: relative;
}

.people-cycle-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.people-cycle-container>* {
  margin: 20px 0;
}

.people-cycle-container a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.people-cycle p {
  text-align: center;
}

.people-cycle .bg-top {
  background-color: #ffffff;
}

.people-cycle-desktop .img-container-desktop {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.people-cycle-desktop .cycle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 150px;
  margin-bottom: 50px;
  height: 100%;
}

.people-cycle-desktop .cycle-main-image {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70%;
}

.people-cycle-desktop .cycle-circle-image {
  position: absolute;
  top: 0;
  left: 0;
}

.people-cycle-desktop .cycle-btn {
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}

.people-cycle-desktop .cycle-box0 {
  top: -83px;
  left: 240px;
}

.people-cycle-desktop .cycle-box1 {
  top: 57px;
  right: -116px;
}

.people-cycle-desktop .cycle-box2 {
  top: 330px;
  left: -170px;
}

.people-cycle-desktop .cycle-box3 {
  top: 335px;
  right: -170px;
}

.people-cycle-desktop .cycle-box4 {
  left: -125px;
  bottom: 23px;
}

.people-cycle-desktop .cycle-box5 {
  right: -95px;
  bottom: -2px;
}

.people-cycle-desktop .cycle-box6 {
  top: 52px;
  right: 590px;
}


.people-cycle-desktop .cycle-box {
  width: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
}

.people-cycle-desktop .cycle-box a {
  font-size: 20px;
  font-weight: bold;
}

.people-cycle-desktop p,
.people-cycle-desktop img,
.people-cycle-desktop h4 {
  margin: -5px;
  padding: 5px;
  background-color: #fff;
  border-radius: 20px;
}

.people-cycle-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.people-cycle-mobile .cycle-boxes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.people-cycle-mobile .cycle-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  text-align: center;
  margin: 40px 0;
}

.people-cycle-mobile .cycle-box h4 {
  margin: 15px 0 5px;
}

.people-cycle-mobile .img-container-mobile {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.people-cycle-mobile .main-image {
  width: 100%;
}

.people-showcase {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.people-showcase::before {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  width: 110%;
  height: 110%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-image: url("https://cms.computicket.com/api/assets/mma/d776beb4-683b-46c8-8a13-b4e06554a3aa/vector-pattern.png?version=0"); */
  opacity: 0.15;
}

.people-showcase-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 150px;
  /* position: relative; */
  min-height: 400px;
  height: 400px;

}

.mainslideImage {
  min-height: 400px;
  background-size: cover;

}

.people-showcase-left {
  margin-left: 10%;
  margin-top: 80px;
  z-index: 10;
  position: relative;
}

.people-showcase-right {
  position: absolute;
  top: 50%;
  right: 10%;
  max-height: 400px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.people-showcase-color-text {
  margin-right: 50px;
}

.people-showcase-color-text .text-row {
  text-align: left;
  color: #ffffff;
  margin: 10px 0;
  width: fit-content;
  padding: 10px 10px 5px;
  white-space: nowrap;
  font-size: 50px;
}

.people-showcase p {
  font-weight: 300;
  font-size: 34px;
  color: #ffffff;
  margin: 30px 0 10px;
  text-align: left !important;
}

.people-showcase .btn-blue-light {
  margin-left: 0;
}

.people-showcase .buttons {
  margin-top: 10px;
  text-align: left !important;
}

.people-showcase .bg-bot {
  background-color: #5f2f65;
  height: 100px;
  clip-path: polygon(0 0, 100% 0, 0 100px, 0 0);
  bottom: -99px;
}

.people-showcase .bg-top {
  top: 1px;
  background-color: #fff;
}

.link-card-showcase {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #0a9ba9;
}

.staticBanner {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.link-card-showcase::before {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("https://cms.computicket.com/api/assets/mma/d776beb4-683b-46c8-8a13-b4e06554a3aa/vector-pattern.png?version=0");
  opacity: 0.2;
}

.link-card-showcase .lead {
  max-width: 400px;
}

.link-card-showcase .text-row {
  background-color: #5f2f65;
}

.link-card-showcase .text-row-3rd {
  background-color: #743c7a;
}

.link-card-showcase-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 150px;
  position: relative;
}

.link-card-showcase-left {
  margin-left: 20%;
  margin-top: 80px;
  z-index: 10;
  position: relative;
}

.link-card-showcase-right {
  position: absolute;
  top: 0;
  right: 10%;
}

.link-card-showcase-color-text {
  margin-right: 50px;
}

.link-card-showcase-color-text .text-row {
  text-align: left;
  color: #ffffff;
  margin: 10px 0;
  width: fit-content;
  padding: 10px 10px 5px;
  white-space: nowrap;
  font-size: 40px;
}

.link-card-showcase p {
  font-weight: 300;
  font-size: 34px;
  color: #ffffff;
  margin: 30px 0 10px;
  text-align: left !important;
}

.link-card-showcase .btn-blue-light {
  margin-left: 0;
}

.link-card-showcase .buttons {
  margin-top: 10px;
  text-align: left !important;
}

.link-card-showcase .bg-bot {
  background-color: #5f2f65;
  height: 100px;
  clip-path: polygon(0 0, 100% 0, 0 100px, 0 0);
  bottom: -99px;
}

.link-card-showcase .bg-top {
  top: 1px;
  background-color: #fff;
}

.people-steps {
  margin: 80px auto;
  background-color: #743c7a;
  color: #ffffff;
}

.people-steps h2 {
  color: #ffffff;
  margin: 0 auto 80px;
}

.people-steps h4 {
  margin-top: 10px;
}

.people-steps-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
}

.people-steps .steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 800px;
  margin: 0 auto;
}

.people-steps .step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.people-steps .bg-bot,
.people-steps .bg-top {
  background-color: #743c7a;
}

.promo-widget {
  margin: 80px auto 0;
  background-color: #ffffff;
  color: #454f5b;
}

.promo-widget-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
  position: relative;
}

.create-code {
  margin: 80px auto 0;
  background-color: #ffffff;
  color: #454f5b;
}

.create-code-container {
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
  position: relative;
}

.create-code .copybtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.create-code .toast {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #eee;
  font-weight: bold;
  padding: 15px;
  font-size: 16px;
  border-radius: 4px;
  z-index: 50;
}

.create-code .share-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #f1f2f6;
  width: 100%;
  max-width: 650px;
  height: min-content;
  z-index: 50;
  opacity: 1;
  border-radius: 25px;
  padding: 20px 10px;
  transform: translate(-50%, -50%);
}

.create-code .share-modal .close-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.create-code .create-widget-container {
  max-width: 650px;
  margin: 0 auto;
  background-color: #eee;
  border-radius: 30px;
  position: relative;
}

.create-code .create-widget-container .hstack {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.create-code .create-widget-container .btn-container {
  margin: 0;
}

.create-code .create-widget-container .form-group.checkbox {
  margin: 0;
  margin-top: 50px;
}

.create-code .create-widget-container .form-group input:disabled::placeholder {
  color: white;
}

.create-code .create-widget-container .inner {
  width: 100%;
  height: 100%;
  padding: 50px;
  position: relative;
}

.create-code .create-widget-container .inner.faded::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  z-index: 20;
}

.create-code .create-widget-container h2 {
  font-size: 40px;
}

.create-code .create-widget-container p {
  text-align: center;
  margin-top: 15px;
}

.create-code .create-widget-container .indicator-wrapper {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-code .create-widget-container .form-group {
  margin: 40px 0 0;
}

.create-code .create-widget-container .form-group.checkbox label {
  font-weight: bold;
}

.create-code .create-widget-container .form-group.checkbox label a {
  margin-left: 4px;
  color: #454f5b !important;
}

.create-code .create-widget-container .error-text {
  margin: 0;
  margin-top: -30px;
  text-align: left;
  font-size: 14px;
  color: #ff0000;
}

.create-code .existing-codes {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.create-code .existing-codes h3 {
  font-size: 40px;
  text-align: center;
  width: 100%;
}

.create-code .existing-codes .share-header {
  text-align: right;
  font-weight: bold;
  font-size: 20px;
  width: 80%;
  margin: 10px auto 0;
}

.create-code .share-buttons .icons {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 10px;
}

.create-code .share-buttons .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-code .share-buttons .icon p {
  font-size: 16px;
  margin-top: 6px;
}

.create-code input#promoCode.input-created {
  color: #0a9ba9 !important;
}

.create-code input#promoCode.input-created:disabled {
  background-color: #fff;
}

.create-code .existing-code {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #ced6e0;
}

.create-code .existing-code .code-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.create-code .existing-code .code-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-code .existing-code .code-wrapper.share {
  border: none;
  cursor: pointer;
  color: inherit;
  background: transparent;
}

.create-code .existing-code .code-wrapper.share:hover {
  background-color: transparent;
}

.create-code .existing-code p {
  margin: 0;
  color: #a4b0be;
  font-weight: bold;
}

.reg-end {
  margin: 80px auto 0;
  background-color: #ffffff;
  color: #454f5b;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reg-end .btn {
  height: 60px;
}

.reg-end h2 {
  color: #454f5b;
  margin: 20px auto 0;
}

.reg-end h3 {
  font-size: 30px;
}

.reg-end h4 {
  margin-top: 10px;
}

.reg-end p {
  text-align: center;
  margin: 20px auto;
}

.reg-end .lead {
  font-size: 25px;
  margin: 50px 0;
}

.reg-end .hr-line {
  background: #747d8c;
  height: 1px;
  width: 80%;
  margin: 50px auto;
}

.reg-end .login-link {
  font-weight: bold;
}

.reg-end .section-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reg-end .section-title>* {
  margin: 0 10px;
}

.reg-end .choices {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 50px 0;
}

.reg-end .choice {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
}

.reg-end .choice p {
  text-align: left;
  margin: 0;
}

.reg-end .choice-title {
  font-weight: bold;
  margin: 0;
}

.reg-end .choice-disclaimer {
  font-weight: bold;
  font-style: italic;
}

.reg-end .choice .flex-gallery {
  margin-top: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.reg-end .choice .flex-gallery>* {
  margin: 10px 0;
}

.reg-end .choice .contact {
  margin: 20px 0;
}

.reg-end .choice .contact .tel {
  font-weight: bold;
  font-size: 24px;
}

.reg-end .or {
  margin: auto 50px;
}

.reg-end .step-list p {
  text-align: left;
  margin: 0;
}

.reg-end .step-list .list-disclaimer {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
}

.reg-end .step-list .sub-content {
  margin-left: 40px;
  margin-top: -20px;
}

.reg-end .step-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  margin: 20px 0;
  gap: 5px;
}

.reg-end .step-list-item img {
  margin-right: 10px;
}

.reg-end .step-list-item.done {
  font-weight: bold;
}

.topUpItem {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 20px auto;
}


.topUpItemImg {
  flex: 30%;
}

.topUpItemHeading {
  flex: 10%;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 20px;

}

.topUpItemBody {
  flex: 60%;
  font-size: 20px;

}

.topUpItemBtn {
  margin-top: 20px;
  flex: 5%;
}

.switchStepTabBtnItem {
  flex: 1;
  margin: auto;
  margin-bottom: 20px;
}

.contacts {
  margin-top: 38px;
}

.reg-end .contacts {
  margin: 50px 0;
}

.reg-end .access-split {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 0 50px;
}

.reg-end .access-split h2 {
  font-size: 30px;
  line-height: 30px;
  text-align: left;
}

.reg-end .access-split h4 {
  font-size: 20px;
}

.reg-end .access-split p {
  text-align: left;
  margin: 0;
}

.reg-end .access-split-left img {
  width: 100%;
}

.reg-end .access-split-right-contents {
  margin-left: 50px;
  padding: 50px 0;
}

.reg-end .access-split-right-contents .flex-gallery {
  justify-content: flex-start;
  align-items: flex-start;
}

.reg-end .access-split-right-contents .flex-gallery>* {
  margin-left: 0;
}

.reg-end .access-split-right-contents .contact-checkers {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0 0;
}

.reg-end .access-split-right-contents .contact-checkers>* {
  font-weight: bold;
  margin-right: 5px;
}

.spinner .lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.spinner .lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ce181e;
  animation: lds-grid 1.2s linear infinite;
}

.spinner .lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}

.spinner .lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}

.spinner .lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}

.spinner .lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}

.spinner .lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}

.spinner .lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}

.spinner .lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}

.spinner .lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}

.spinner .lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.stuck {
  margin: 80px auto;
  text-align: center;
}



.stuck-container {
  padding-top: 80px;
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
}

.stuck h2 {
  color: #454f5b;
  margin: 20px 0;
  font-size: 25px;
  ;
}

.stuck p {
  color: #454f5b;
  margin: 20px 0;
  font-size: 18px;
}

.stuck .boxes {
  margin: 0;
}

.stuck .box-questions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stuck .box:first-of-type {
  border-right: 1px solid #2f3542;
}

.stuck .bg-bot {
  background-color: #ce181e;
}

.testimonials {
  margin: 80px auto;
  background-color: #747d8c;
  position: relative;
  top: -3px;
  color: #ffffff;
}

.testimonials h2 {
  color: #ffffff;
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto 50px;
}

.testimonials h6 {
  font-weight: normal;
  font-size: 20px;
  font-family: "Fredoka One", cursive;
  margin: 0;
  text-align: left;
  width: 100%;
}

.testimonials h6:first-of-type {
  margin-top: 20px;
}

.testimonials h6:last-of-type {
  margin-bottom: 20px;
}

.testimonials-container {
  margin: 0 auto;
}

.testimonials .testimonies {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.testimonials .swiper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin: 0 auto;
}

.testimonials .swiper-container {
  width: 100%;
  max-width: 1040px;
}

.testimonials .swiper-button-prev,
.testimonials .swiper-button-next {
  color: #ffffff;
  top: 40%;
  font-weight: bold;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.testimonials .swiper-button-prev::after,
.testimonials .swiper-button-next::after {
  font-size: 35px !important;
}

.testimonials .swiper-button-prev {
  margin-left: -30px;
}

.testimonials .swiper-button-next {
  margin-right: -30px;
}

.testimonials .swiper-button-disabled {
  opacity: 0 !important;
}

.testimonials .swiper-slide {
  max-width: 260px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.testimonials .swiper-slide p,
.testimonials .swiper-slide h6 {
  color: #ffffff;
  padding: 0 10px;
}

.testimonials .swiper-slide h6 {
  text-align: left;
}

.testimonials .swiper-slide img {
  width: 90%;
  max-width: 180px;
}

.testimonials .btn-swiper {
  width: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.testimonials .bg-bot,
.testimonials .bg-top {
  background-color: #747d8c;
}

.xtra-savings-info {
  padding: 50px 0;
}
.xtra-savings-hyper-info {
  padding: 50px 0 0 0;
  background-color : #F1F2F5;
}

.xtra-saving-new-image_content_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.xtra-savings-info-container {
  margin: 0 auto;
  width: clamp(300px, 90%, 1800px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.xtra-savings-info-container-new {
  margin: 0 auto;
  width: clamp(300px, 90%, 1800px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.xtra-saving-new-image-content-container {
  display: flex;
  flex-direction: row;
}

.xtra-saving-new-Image {
  margin-right: 40px;
}

.xtra-saving-new-Image img {
  max-width: 290px;
}

.xtra-saving-ul {
  list-style-type: disc;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 40px;

}

.numberBullets {

  list-style-type: decimal !important;

}

.numberBulletsValue {}

.numberBulletsValue1 {
  padding-left: 14px;
}



.xtra-saving-ul li {
  color: #57606F;
  padding-left: 5px;
}


.Xtra-savings-store-logos-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center
}

.Xtra-savings-store-logo-div-second {
  padding-left: 20px;
}

.Xtra-savings-store-logo {
  width: 100%;
}

.xtra-saving-end-text {
  color: #57606F;

}

.xtra-savings-info-content {
  margin: 0 20px;
}

.componentPadding {
  padding: 80px 0px;
  margin-bottom: -100px;
}

.componentPaddingCustom {
  padding-top: 120px;

}

.unclaimedButton {
  font-size: 18px;
}

.deals-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.deals-info-title {
  font-size: 40px;
  color: #2F3542;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Fredoka One", cursive;


}

.deals-info-content {
  margin: 0 60px;
  max-width: 650px;

}

.deals-info-content p {
  text-align: left;
}

.deals-info-content-text {
  font-size: 25px;
}

.deals-info-content-text-space {
  font-size: 25px;
  margin-bottom: 20px;
}

.xtra-savings-info p {
  text-align: center;
}

.xtra-savings-info h2 {
  margin-bottom: 20px;
}

.xtra-savings-info-steps-header {
  margin-bottom: 20px;
  color: white !important;
}

.xtra-savings-info-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
}

.xtra-savings-info-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  max-width: 200px;
  text-align: center;
}

.xtra-savings-info-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.xtra-savings-info-buttons-left {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.xtra-savings-info-buttons>* {
  margin: 10px 0;
}

.who-mma {
  margin-top: 80px;
  background-color: #ce181e;
  text-align: center;
}

.who-mma-container {
  padding-top: 80px;
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
}

.who-mma h2 {
  color: #ffffff;
  margin: 20px auto;
}

.who-mma p {
  color: #ffffff;
  margin: 20px auto;
}

.who-mma .bg-bot {
  background-color: #ce181e;
}

.where-retailer-container {
  width: clamp(300px, 90%, 1800px);
  margin: 200px auto 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.where-retailer h2 {
  margin-bottom: 10px;
}

.where-retailer p {
  text-align: center;
}

.where-retailer-flex-gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin: 50px;
  max-width: 700px;
}

.where-retailer-flex-gallery>* {
  margin: 20px;
  max-width: 200px;
  max-height: 50px;
}

.where-retailer-flex-gallery>*>img {
  width: 100%;
  height: 100%;
}

.modal-faq {
  position: absolute;
  padding: 10px;
  top: 250px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: clamp(300px, 90%, 1800px);
  background-color: #ffffff;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  z-index: 100;
  opacity: 0.95;
  border-radius: 50px;
}

.modal-faq-container {
  width: clamp(300px, 90%, 1800px);
  position: relative;
  padding: 10px;
}

.modal-faq .btn-close {
  position: absolute;
  top: 0 !important;
  right: 5px !important;
  left: unset !important;
}

.modal-faq p {
  text-align: left;
}

.modal-do-more {
  position: fixed;
  padding: 10px;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  z-index: 100;
  opacity: 0.95;
  border-radius: 50px;
  z-index: 999999;
  overflow-y: hidden;

}

.modal-do-more-container {
  width: clamp(300px, 90%, 1800px);
  position: relative;
  padding: 10px;
}

.modal-do-more .btn-close {
  position: absolute;
  top: 0 !important;
  right: 5px !important;
  left: unset !important;
}

.modal-do-more-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.modal-do-more p {
  text-align: center;
  margin-left: 20px;
}

.modal-do-more h2 {
  color: #454f5b;
}

.banking-container {
  width: clamp(300px, 90%, 1800px);
  margin: 200px auto -100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.banking-container-image {
  margin: 100px auto;
}

.banking-container-image img {
  width: 100%;
}

.fees {
  margin: 200px auto 0;
}

.fees-tabs-selectors {
  background-color: #ffffff;
  margin: 0 auto;
  width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 50px;
  border: 1px solid #0a9ba9;
  margin: 50px auto;
}

.fees-tabs-selector {
  border-radius: 50px;
  background-color: #ffffff;
  width: 100%;
  text-align: center;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fees-tabs-selector-business {
  color: #0a9ba9;
}

.fees-tabs-selector-recipients {
  color: #5f2f65;
}

.fees-tabs-selector h6 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.fees-tabs-selector-business.react-tabs__tab--selected {
  background-color: #0a9ba9;
  color: #ffffff;
}

.fees-tabs-selector-recipients.react-tabs__tab--selected {
  background-color: #5f2f65;
  color: #ffffff;
}

/* start */

.limit-tabs-selectors {
  background-color: #ffffff;
  margin: 0 auto;
  width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 50px;
  border: 1px solid #743C7A;
  margin: 50px auto;
}

.limit-tabs-selector {
  border-radius: 50px;
  background-color: #ffffff;
  width: 100%;
  text-align: center;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.limit-tabs-selector-business {
  color: #919EAB;
}

.limit-tabs-selector-recipients {
  color: #919EAB;
}

.limit-tabs-selector h6 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.limit-tabs-selector-business.react-tabs__tab--selected {
  background-color: #743C7A;
  color: #ffffff;
}

.limit-tabs-selector-recipients.react-tabs__tab--selected {
  background-color: #743C7A;
  color: #ffffff;
}

/* end */

.fees-tabs-content {
  width: 100%;
  margin: 0 auto;
}

.fees-tabs-content h2 {
  margin: 0 auto;
}

.fees-tabs-content p {
  margin: 20px auto;
  text-align: center;
}

.fees-tabs-content ul {
  max-width: 600px;
  margin: 40px auto;
}



.fees-tabs-content li {
  color: #57606f;
  border-bottom: 1px solid #57606f;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}

.fees-tabs-content li span:last-of-type {
  margin-left: 10px;
}

.fees-tabs-content li:first-of-type {
  border-top: 1px solid #57606f;
}


/* start */
.limits-tabs-content {
  width: 100%;
  margin: 0 auto;
}

.limitItemHeader {
  font-weight: bold;
  font-size: 20px;
}

.limitItemDescription {
  font-weight: normal;
  font-size: 18px;
}

.limits-tabs-content h2 {
  margin: 0 auto;
}

.limits-tabs-content p {
  margin: 20px auto;
  text-align: center;
}

.limits-tabs-content ul {
  max-width: 600px;
  margin: 40px auto;
  padding: 40px 0px;

}



.limits-tabs-content li {
  color: white;
  border-bottom: 1px solid white;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  flex-direction: column;
  text-align: center;
}

.limits-tabs-content li span:last-of-type {
  margin-left: 10px;
}

.limits-tabs-content li:first-of-type {
  border-top: 1px solid white;
}

/* end */
.how-to-use {
  position: relative;
  margin: 80px auto;
}

.how-to-use-tiles {
  margin: 0 auto;
  width: min-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.how-to-use-tile {
  width: 280px;
  height: 80px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

.how-to-use-tile img {
  margin-right: 10px;
}

.how-to-use-tile h6 {
  font-size: 16px;
  padding: 2px;
}

.how-to-use-tile svg {
  margin-left: auto;
  min-width: 20px;
  min-height: 20px;
}

.how-to-use .bg-bot {
  z-index: 1;
}

.how-to-use-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  position: relative;
  margin: 0 auto;
  font-size: 16px;
}

.how-to-use-section-image {
  max-width: 250px;
  margin: 0 100px;
}

.how-to-use-section-split {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 100px;
}

.how-to-use-section-content {
  padding-left: 10px;
}

.how-to-use-section-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}

.how-to-use-section-heading-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.how-to-use-section-heading-text h6 {
  padding: 10px 0 20px;
  font-size: 20px;
}

.how-to-use-section-flex-gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin: 10px;
}

.how-to-use-section-flex-gallery-prepaid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 80%;
  margin: 0px 20px 10px 0px;
}

.how-to-use-section-flex-gallery-prepaid>* {
  margin: 0px 20px 10px 0px;
}

.how-to-use-section-flex-gallery-link {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0px 20px 20px 0px;
}

.how-to-use-section-flex-gallery-link>* {
  margin: 0px 20px 20px 0px;
}


.how-to-use-section-flex-gallery>* {
  margin: 10px;
}

.how-to-use-section-flex-gallery.button-container {
  justify-content: flex-start;
  margin-right: auto;
}

.how-to-use-section-flex-gallery.brand-logos>*>img {
  max-width: 150px;
  max-height: 25px;
}

.how-to-use-section ul.how-to-use-section-list,
.how-to-use-section ul.how-to-use-section-list>li {
  list-style-type: disc;
}

.how-to-use-section ul.how-to-use-section-list.no-bullet,
.how-to-use-section ul.how-to-use-section-list>li.no-bullet {
  list-style-type: none;
}

.how-to-use-section ol.how-to-use-section-list,
.how-to-use-section ol.how-to-use-section-list>li {
  list-style-type: decimal;
}

.how-to-use-section ol.how-to-use-section-list.no-bullet,
.how-to-use-section ol.how-to-use-section-list>li.no-bullet {
  list-style-type: none;
}

.how-to-use-section ol.how-to-use-section-list.small-letter,
.how-to-use-section ol.how-to-use-section-list.small-letter>li {
  list-style-type: lower-alpha;
}

.how-to-use-section ol.how-to-use-section-list.small-letter.no-bullet,
.how-to-use-section ol.how-to-use-section-list.small-letter>li.no-bullet {
  list-style-type: none;
}

.how-to-use-section-list {
  padding-left: 40px;
  font-size: 16px;
  margin-bottom: 40px;
}

.how-to-use-section-list-item {
  margin: 5px 0;
}

.how-to-use-section-paragraph {
  font-size: 16px;
  margin-bottom: 40px;
}

.how-to-use-section-accordion-item {
  padding-left: 30px;
}

.how-to-use-section-accordion-heading {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 35px 0 5px;
  cursor: pointer;
}

.how-to-use-section-accordion-heading svg {
  margin-left: 10px;
  min-width: 20px;
  min-height: 20px;
}

.how-to-use-section.reverse {
  flex-direction: row-reverse;
}

.how-to-use-section p {
  text-align: left;
}

x.register>.footer {
  margin-top: 0;
}

.register-widget {
  background-image: url("https://cms.computicket.com/api/assets/mma/e5d7ccab-274e-4d27-a041-486a901a834f/bg-red.png?version=0");
  background-position: top left;
  background-repeat: repeat;
  background-size: auto;
  padding: 50px 10px;
  width: 100vw;
}

.register-widget .link-card-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.register-widget .link-head {
  margin-top: 40px;
  font-size: 25px;
}

.register-widget .toast {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #eee;
  font-weight: bold;
  padding: 15px;
  font-size: 16px;
  border-radius: 4px;
  z-index: 50;
}

.register-widget .sep-line {
  background-color: #dfe3e8;
  width: 80%;
  height: 3px;
  margin: 60px auto 0;
}

.register-widget-container {
  max-width: 650px;
  margin: 0 auto;
  background-color: #f1f2f6;
  border-radius: 30px;
  padding: 50px;
}



.register-widget-container .image-container {
  display: 'flex';
  padding-top: 30px;

}

.register-widget-container .image-container img {
  margin: 0px auto 0;
  width: 100%;
  max-width: 650px;
}

.register-widget-container .toast {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #eee;
  font-weight: bold;
  padding: 15px;
  font-size: 16px;
  border-radius: 4px;
  z-index: 50;
}

.register-widget-container .share-buttons button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.register-widget-container .share-buttons .share-copy {
  font-size: 20px;
  color: #57606f;
  margin-top: 40px;
}

.register-widget-container .share-buttons .icons {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 10px;
}

.register-widget-container .share-buttons .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.register-widget-container .share-buttons .icon p {
  font-size: 16px;
  margin-top: 6px;
}

.register-widget-container h2 {
  font-size: 40px;
}

.register-widget-container .highlight {
  padding: 10px;
  color: #fff;
  width: auto;
  white-space: nowrap;
  margin: 0 auto;
  font-weight: bold;
  font-size: 20px;
}

.register-widget-container .highlight-t1 {
  margin-top: 50px;
  background-color: #743c7a;
  width: 90%;
}

.register-widget-container .highlight-t2 {
  background-color: #9a53a1;
  width: 80%;
}

.register-widget-container .sub-header {
  margin: 40px 0 10px;
  font-size: 25px;
  width: 100%;
  text-align: center;
}

.register-widget-container .card-images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 40px 0;
}



.register-widget-container .account-ready-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.register-widget-container .btn-underline {
  text-decoration: underline;
  background-color: transparent;
  border: none;
  color: #57606f;
  cursor: pointer;
}

.register-widget-container .btn-account-ready {
  margin: 0px 0px 20px;
}

.register-widget .register-congrats {
  /* border-top: 2px solid #dfe3e8; */
  margin-top: 20px;
  /* padding-top: 20px; */
}

.register-widget .register-congrats h3 {
  font-size: 25px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.register-widget .register-congrats p {
  font-size: 20px;
  color: #57606f;
}

.register-widget .register-congrats .split {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  margin-top: 20px;
}

.register-widget .register-congrats .split .box-grid:first-of-type {
  border-right: 2px solid #dfe3e8;
}

.register-widget .register-congrats .split p {
  font-weight: bold;
  color: #57606f;
  font-size: 18px;
  margin-bottom: 20px;
}

.register-widget .register-congrats .split .app-img-tiles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.register-widget .register-congrats .split .btn {
  margin: 0 auto;
  width: min-content;
  background-color: #8f2971;
}

.register-widget p {
  text-align: center;
  font-size: 25px;
}

.register-widget .sub-head {
  width: 100%;
  text-align: center;
  margin: 50px 0;
}

.register-widget .user-exists>p {
  margin: 50px 0;
}

.form-switch {
  border: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #454f5b;
  cursor: pointer;
  gap: 4px;
  font-size: 20px;
  margin-top: 20px;
}

.account-type {
  width: 250px;
  height: 130px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin: 50px 10px;
  cursor: pointer;
}

.account-type h4 {
  text-align: center;
}

.account-type.active {
  border: 2px solid #0a9ba9;
}

.account-type.active::before {
  content: "\2713";
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ffffff;
  background-color: #37aa5e;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
  font-size: 20px;
  text-align: center;
}

.account-type-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card-type {
  width: 220px;
  height: 130px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 50px 20px;
}

.card-type h4 {
  text-align: center;
}

.card-type.active {
  border: 1px solid #0a9ba9;
}

.card-type.active::before {
  content: "\2713";
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ffffff;
  background-color: #37aa5e;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
  font-size: 20px;
  text-align: center;
}

.card-type-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.error {
  text-align: left !important;
  font-size: 14px !important;
  margin-right: auto !important;
}

.indicator-wrapper {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-group-register {
  margin-top: 0px !important;
}

.promo-code-header {
  font-size: 15px;
}

.form-group {
  margin: 40px 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.form-group h4 {
  margin-right: auto;
  font-size: 25px;
}

.form-group input:focus {
  outline: 2px solid #0a9ba9;
}

.form-group input:disabled {
  background-color: #aaaaaa;
  color: #000000 !important;
  cursor: not-allowed;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"] {
  width: 100%;
  margin: 0 auto;
  padding: 20px 10px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #454f5b;
}

.form-group input[type="text"]~label,
.form-group input[type="email"]~label,
.form-group input[type="password"]~label {
  margin-right: auto;
  font-size: 20px;
}

.form-group input[type="text"]::placeholder,
.form-group input[type="email"]::placeholder,
.form-group input[type="password"]::placeholder {
  color: #a4afbe;
}

.form-group input[type="checkbox"],
.form-group input[type="radio"] {
  margin-right: 10px;
  min-width: 20px;
  min-height: 20px;
}

.form-group input[type="checkbox"]:focus,
.form-group input[type="radio"]:focus {
  outline: none;
}

.form-group.checkbox {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
}

.form-group.radio {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  height: 90px;
  padding: 5px;
}

.share-heading {
  font-size: 16px;
  color: #8f2971;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.card-container {

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card-container img {
  margin: 10px auto 0px auto;
}

.card-link-sub-head {
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  ;
  font-weight: bold;
  color: #454f5b;
}

.reg-share-head {
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  color: #454f5b;
}

.reg-share-head-share-page {
  font-size: 45px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  color: #454f5b;
}

.reg-share-sub-head {
  font-size: 15px;
  text-align: center;
  margin-top: 10px;
  color: #8F2971;
  margin-bottom: 20px;
}

.card-container>* {
  margin: 20px 0;
}

.flex-gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-gallery>* {
  margin: 20px;
}

.checkbox-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
}

.checkbox-row-sibling {
  margin-bottom: 10px;
}

.checkbox-row>.form-group {
  margin: 0;
  margin-right: 20px;
  width: unset;
}

.form-group>input:disabled {
  background-color: #fff;
  cursor: not-allowed;
}

.ready-copy {
  font-size: 20px !important;
  color: #8f2971;
  font-weight: bold;
  margin-top: 40px;
}

.ready-link {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: -30px;
}

.ready-link-a {
  font-size: 18px;
  color: #57606f;
  cursor: pointer;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container>img {
  margin: 50px 0;
}

.image-container-cards {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.image-container-cards .card-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.image-container-cards .otp-image {
  max-width: 50%;
  margin: 0;
}

.hr-line {
  background: #747d8c;
  height: 1px;
  width: 80%;
  margin: 50px auto;
}

.option-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0;
}

.option-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.option-icon>img {
  margin-right: 10px;
}

.option-sep {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.option-sep h4 {
  margin: 0 10px;
}

.option-sep>.hr-line {
  flex: 1;
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0;
}

.btn-container-center {
  margin: 20px auto 0;
  width: min-content;
  white-space: nowrap;
}

.btn-container-center .btn-share {
  background-color: #8f2971 !important;
}

.btn-register {
  display: block;
  background-color: #0a9ba9;
  color: #ffffff;
  height: 60px;
  line-height: 60px;
  font-size: 25px;
}


.resend-otp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resend-otp p {
  font-size: 16px;
}

.resend-otp-btn {
  font-size: 16px;
  border: 0;
  cursor: pointer;
}

.otp-image {
  width: 100%;
  margin: 0 auto;
}

.fade-enter {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 100ms, transform 300ms;
}

.form-group input:disabled {
  background-color: #fff;
  color: #0a9ba9 !important;
}

@media (max-width: 600px) {

  .Xtra-savings-store-logos-container {
    flex-wrap: wrap;
  }

  .Xtra-savings-store-logo-div {
    padding-left: unset;
    padding-right: unset;
    margin: 10px 0px;
  }

  .paragraph-list p {
    margin: 0;
    /* Remove default paragraph margins */
    text-align: left;
    text-indent: -20px;
    /* Adjust this value as needed */
  }

  .unclaimedButton {
    margin: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .business-stepsCustomMargin {
    margin-top: 40px;
  }

  .paragraph-list {
    margin: 20px 0px 40px 20px;
  }

  .stuck {
    margin: 0px auto !important;
    text-align: center;
  }

  .stuck-container {
    padding-top: 0px;
  }

  .register-widget-container {
    padding: 30px;
  }

  .register-widget-container .card-images {

    grid-gap: 10px;
  }

  .register-widget-container .card-images img {
    width: 140px;
  }

  .business-showcase-right {
    right: -7% !important;
    top: 60px !important;
    height: 100% !important;
    width: 100% !important;
  }

  .topUpItemImg img {
    width: 150px;
    height: auto !important;
    flex: 30%;
  }

  .topUpItemHeading {
    font-size: 18px;

  }

  .topUpItemBody {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }



  .business-showcase-right img {
    height: 100%;
  }

  .how-to-use-section-image {
    max-width: 150px;
    margin-top: -30px !important;
  }

  .how-to-use-section-split {
    margin-top: 30px !important;
  }
}

.stuck-container-banking {
  padding-top: 0px;
  width: clamp(300px, 90%, 1800px);
  margin: 0 auto;
}

.stepButtonPadding {}


@media (max-width: 1000px) {

  .shareSubHeader {

    font-size: 16px;
    font: normal;

  }

  .switchTabSubHeader {

    font-size: 16px;
  }

  .switchStepsTabItemContainerSubHead {
    font-size: 18px;
    margin-top: 40px
  }

  .swithTabButton {
    border-radius: 50px;
    color: white;
    font-size: 22px;
    background-color: transparent;
    border: 1px solid white;
    font-weight: bold;
    max-width: 200px;
    text-align: center;
    margin: 0px 10px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .stepButtonPadding {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }

  .switchStepTabText {
    font-size: 16px;
  }

  .whatsapp-Basic-Body {
    height: 100%;
    margin-bottom: 20px;

  }

  .how-to-use-section-whatsapp {
    /* margin-top: -80px !important; */
    margin-top: -20px !important;
    margin-bottom: -30px !important;
  }

  .how-to-deposit-money-whatsapp {
    margin-bottom: 80px;
  }

  .business-steps-container-whatsapp {
    margin-top: -30px;
    margin-bottom: -60px;
  }


  .whatsapp-basics {
    margin: 60px auto 0;
  }

  .whatsapp-basics-features {
    margin-bottom: -10px;
  }

  .whatsapp-Basic-Link {
    margin-bottom: 40px;

  }

  .whatsapp-basics-feature {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 315px;
    flex: 100%;
    max-width: 100% !important;


  }

  .whatsapp-basics-feature p {

    max-width: 100% !important;


  }

  .people-cycle-container a {
    font-size: 16px;
    font-weight: bold;
  }

  .stuck-banking {
    margin: 10px auto -90px auto !important;
    text-align: center;
  }

  .whySwitchHeader {
    margin-top: 40px !important;
  }

  body.scroll-down nav {
    transform: none;
  }

  .swiper-wrapper {
    justify-content: stretch;
  }

  body.scroll-up nav {
    filter: none;
  }

  h2 {
    padding: 0 5px;
    font-size: 25px;
    line-height: 30px;
  }

  p,
  p.how-mma-business-lead,
  .stuck p,
  .app-tiles p,
  .demo-lead,
  .faq-tabs-sub-heading,
  .faq-tabs-sub-content,
  .contact-a,
  .faq-tabs .search-input,
  .modal-demo-receive .list {
    font-size: 16px !important;
  }



  nav {
    height: 80px !important;
  }

  nav ul {
    justify-content: unset !important;
    align-items: unset !important;
  }

  nav .nav-logo-wrapper {
    width: 100%;
  }

  nav .navbar-sub-list li a {
    padding: 5px 10px;
  }

  .about,
  .for-business,
  .for-people,
  .help,
  .not-found {
    margin-top: 120px;
  }

  .nav-button-right {
    order: 2;
    min-width: unset !important;
    line-height: unset;
    display: grid;
    place-content: center;
    margin-left: auto !important;
  }

  nav button {
    order: 3;
  }

  .about-showcase-container {
    padding: 10px;
  }

  .about-showcase .btn {
    display: block;
    margin-left: 0;
    margin-right: 0;
    max-width: 200px;
  }

  .about-showcase .text-row {
    font-size: 20px;
    padding: 0px 10px 0px;
    line-height: 40px;
    margin: 5px 0;
  }

  .about-showcase-right {
    right: 10px;
    top: 430px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .about-showcase-right img {
    height: 420px;
  }

  .about-showcase-left {
    margin-left: 10px;
    margin-top: 50px;
  }

  .about-showcase p {
    font-size: 20px;
  }



  .mma-basics .mma-basics-features {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .who-mma .boxes {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .who-mma .box {
    padding: 50px 0;
    margin: 20px 0;
  }

  .who-mma .box:first-of-type {
    border-right: none;
    border-bottom: 1px solid #ffffff;
  }

  .business-showcase-container {
    padding: 50px 0 0 0;
  }

  .business-showcase .text-row {
    font-size: 20px;
    padding: 0px 10px 0px;
    line-height: 40px;
    margin: 5px 0;
  }

  .business-showcase-right {
    right: -35%;
    top: 60px;
    height: 100%;
    width: 100%;
  }

  .business-showcase-right img {
    height: 100%;
  }

  .business-showcase-left {
    margin-left: 10px;
    margin-top: 0;
  }

  .business-showcase p {
    font-size: 20px;
  }

  .business-showcase .btn {
    display: block;
    margin-left: 0;
    margin-right: 0;
    max-width: 200px;
  }

  .mma-summary {
    margin: -200px auto 0;
  }

  .mma-summary .mma-summary-container {
    grid-template-columns: repeat(1, 1fr);
    padding-top: 100px;
  }

  .mma-summary .mma-summary-left {
    display: none;
  }

  .mma-summary .mma-summary-right {
    padding: 0;
    justify-content: center;
    text-align: center;
  }

  .mma-summary .mma-summary-right h2 {
    width: 100%;
  }

  .how-mma-business {
    margin: -100px auto 0;
  }

  .how-mma-business .custom-select {
    width: 300px;
  }

  .how-mma-business .how-mma-business-boxes {
    margin-top: 80px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: -60px;
    grid-template-columns: repeat(1, 1fr);
  }

  .business-slider {
    width: clamp(300px, 90%, 1800px);
    margin: 80px auto -60px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .business-tabs .business-tabs-selectors {
    width: 95%;
  }

  .business-tabs .business-tabs-content-image {
    width: 100%;
    right: 50%;
    bottom: -230px;
    transform: translateX(50%);
    object-fit: cover;
    display: grid;
    place-content: center;
  }

  .business-tabs .business-tabs-content-image img {
    height: 250px;
    width: unset;
  }

  .business-tabs .business-tabs-content-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 20px 5px;
    margin: 0 auto;
  }

  .business-tabs-content-text h2 {
    text-align: left !important;
  }

  .business-tabs .business-tabs-content {
    padding: 0;
  }

  .business-tabs-content {
    width: 90%;
  }

  .how-mma-business-box img {
    width: 60px;
    margin-right: 10px;
  }

  .business-tabs .business-tabs-content h2 {
    /* margin: 0 auto; */
    padding: 10px 10px;
    /* border-radius: 50px; */
    text-align: left;
    align-self: flex-start;
    margin-left: 10px;
  }

  .business-tabs .business-tabs-content p {
    /* margin: 0 auto; */
    padding: 5px 0px 0px 0px;
    /* border-radius: 50px; */
    text-align: left;
    align-self: flex-start;
    margin-left: 10px;
  }

  .business-tabs .business-tabs-content .fade-text {
    font-size: 20px;
  }

  .business-tabs .business-tabs-content p {
    text-align: center;
  }

  .business-tabs .ticks {
    padding-left: 15px;
    align-self: flex-start;
  }

  .business-tabs .btn {
    display: block;
  }

  .business-steps .business-steps-steps {
    width: 100%;
  }

  .business-steps .business-steps-step-right {
    word-wrap: anywhere;
  }

  .clients .clients-stats {
    flex-direction: column;
  }

  .clients .clients-stat {
    margin: 20px 0;
  }

  .stuck .boxes {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .stuck .box {
    padding: 50px 0;
    word-wrap: anywhere;
  }

  .stuck .box:first-of-type {
    border-right: none;
    border-bottom: 1px solid #2f3542;
  }

  .people-showcase .text-row {
    padding: 10px 10px 5px;
    line-height: unset;
    font-size: 25px;
  }

  .people-showcase-right {
    display: none;
  }

  .do-more-selectors {
    width: 100%;
  }

  .do-more-selector p {
    font-size: 12px;
  }

  .do-more-selector h6 {
    font-size: 16px;
  }

  .do-more-grid {
    flex-direction: column;
  }

  .do-more-grid-col-img {
    display: none;
  }

  .do-more-grid-icon {
    flex-direction: row !important;
    text-align: left !important;
    margin: 20px 0;
  }

  .modal-do-more {
    width: 98%;
  }

  .modal-do-more .btn-close {
    top: -10px !important;
  }

  .top-tip {
    padding-left: 20px;
    margin-top: -51px;

  }

  .top-tip img {
    display: none;
  }

  .app-tiles-selectors {
    flex-direction: column;
  }

  .app-tiles-selector {
    width: max-content;
    flex: 1;
  }

  .app-tiles-selector.react-tabs__tab--selected>.tab-tile {
    background-color: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }

  .app-tiles-selector.react-tabs__tab--selected>.tab-tile p {
    color: #0a9ba9;
  }

  .app-tiles .tab-tile {
    width: max-content;
    height: min-content;
  }

  .app-tiles .tab-tile img {
    display: none;
  }

  .app-tiles .arrow {
    display: none;
  }

  .app-tiles .tab-content .block {
    padding: 10px;
  }

  .app-tiles .tab-content .ussd {
    font-size: 40px;
  }

  .app-tiles .tab-content .tel {
    font-size: 25px;
  }

  .app-tiles .tab-content .btn {
    margin: 0 auto;
  }

  .app-tiles .tab-content .split {
    flex-direction: column;
  }

  .app-tiles .tab-content .split>div:first-of-type {
    border-right: none;
    border-bottom: 1px solid #6d6e70;
  }

  .how-to-header-selectors {
    width: 100%;
  }

  .how-to-header-selector p {
    font-size: 12px;
  }

  .how-to-header-selector h6 {
    font-size: 16px;
  }

  .how-to-use-tiles {
    grid-template-columns: repeat(1, 1fr);
  }

  .how-to-use-section {
    flex-direction: column;
  }

  .how-to-use-section.reverse {
    flex-direction: column;
  }

  .how-to-use-section-image {
    margin: 0 auto;
  }

  .how-to-use-section-split {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .how-to-use-section-accordion-item {
    padding-left: 0;
  }

  .launch-offer-split {
    flex-direction: column;
  }

  .launch-offer-split-left {
    margin: 0;
  }

  .launch-offer-purple {
    width: 100%;
  }

  .launch-offer-purple h2 {
    font-size: 50px;
    line-height: 50px;
  }

  .link-card-showcase-left {
    margin-left: 20px;
  }

  .link-card-showcase-right {
    display: none;
  }

  .link-card-tiles-tab-container {
    padding: 0;
  }

  .link-card-tiles-selectors {
    flex-direction: column;
  }

  .link-card-tiles-selector {
    width: max-content;
    flex: 1;
  }

  .link-card-tiles-selector.react-tabs__tab--selected>.tab-tile {
    background-color: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }

  .link-card-tiles-selector.react-tabs__tab--selected>.tab-tile p {
    color: #5f2f65;
  }

  .link-card-tiles .tab-content .step-container {
    flex-direction: column;
    align-items: center;
  }



  .link-card-tiles .tab-tile {
    width: max-content;
    height: min-content;
  }

  .link-card-tiles .tab-tile img {
    display: none;
  }

  .link-card-tiles .arrow {
    display: none;
  }

  .xtra-savings-info-container {
    flex-direction: column;
  }

  .xtra-savings-info-icons {
    flex-direction: column;
    align-items: center;
  }

  .fees-limits-section {
    padding: 50px 10px;
  }

  .people-steps .steps {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .people-steps .step {
    margin: 20px 0;
  }

  .testimonials .swiper-container {
    max-width: 260px;
  }

  .clients .swiper-container {
    max-width: 260px;
  }

  .faq-tabs .faq-tabs-selectors {
    width: 100%;
  }

  .faq-tabs .search-field {
    width: 100%;
  }

  .faq-tabs .faq-tabs-main {
    width: 100%;
  }

  .faq-tabs .faq-tabs-sub {
    /* width: 85%; */
    width: 100%;
  }

  .faq-tabs .search-wrapper {
    width: 100%;
  }

  .fees-tabs-selectors {
    width: 100%;
  }

  .limit-tabs-selectors {
    width: 100%;
  }

  .fees-tabs-content li {
    font-size: 16px;
  }

  .checkbox-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn-container {
    flex-direction: column;
  }

  .btn-container>.btn {
    margin: 20px;
  }

  .card-type-container {
    flex-direction: column;
  }

  .flex-gallery {
    flex-direction: column;
  }

  nav .navbar-sub-list {
    position: relative !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
  }

  nav .navbar-sub-list li:hover a {
    color: #454f5b !important;
  }

  nav .navbar-sub-list li:hover {
    background-color: transparent;
  }

  .footer .footer-grid-links {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }

  .footer .footer-grid-logos {
    flex-direction: column;
  }

  .footer .footer-grid-logos a {
    margin: 20px 0;
  }

  .footer .footer-grid-internals {
    flex-direction: column;
    width: 100%;
  }

  .four-oh-four .massive {
    font-size: 100px;
    line-height: 120px;
  }

  .link-card-promo img {
    max-width: 100%;
  }

  .link-card-promo .how-split {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .link-card-promo .how-split>* {
    width: 100%;
  }

  .link-card-promo .icon-boxes {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: unset;
  }

  .reg-end {
    padding: 0 10px;
  }

  .reg-end img {
    max-width: 100%;
  }

  .reg-end .access-split {
    flex-direction: column;
  }

  .reg-end .access-split-right-contents .contact-checkers {
    flex-direction: column;
  }

  .create-code .create-widget-container h2 {
    font-size: 32px;
  }

  .create-code .create-widget-container .inner {
    padding: 20px 10px;
  }

  .create-code .existing-codes h3 {
    font-size: 26px;
  }

  .create-code .existing-code p {
    font-size: 14px;
  }

  .create-code .existing-codes .share-header {
    font-size: 16px;
  }

  .promo-widget,
  .create-code {
    margin-top: 50px;
  }

  .create-code .create-widget-container .form-group.checkbox label {
    font-size: 12px;
  }

  .btn {
    font-size: 18px;
    line-height: 48px;
    height: 48px;
  }

  .form-switch {
    font-size: 14px;
    display: block;
  }

  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group input[type="password"] {
    font-size: 16px;
  }

  .form-group input[type="text"]~label,
  .form-group input[type="email"]~label,
  .form-group input[type="password"]~label {
    font-size: 16px;
  }

  .register-widget-container h2 {
    font-size: 32px !important;
  }

  .timerHeader {
    font-size: 27px !important;

  }

  .create-code .share-buttons .icons {
    grid-template-columns: repeat(2, 11fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 50px 10px;
  }

  .create-code .create-widget-container .hstack {
    margin-top: 20px;
    flex-direction: column;
    gap: 20px;
  }

  .create-code .create-widget-container .hstack>.btn {
    margin: 0;
  }

  .register-widget .register-congrats .split {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 50px;
  }

  .register-widget .register-congrats .split .box-grid:first-of-type {
    border-right: none;
    border-bottom: 2px solid #dfe3e8;
    padding-bottom: 60px;
  }
}

.ch.register h1,
.ch.register h2,
.ch.register h3,
.ch.register h4,
.ch.register h5,
.ch.register h6,
.ch.register p,
.ch.register li,
.ch.register ul,
.ch.register ol,
.ch.register div,
.ch.register span,
.ch.register a,
.ch.register button,
.ch.promo h1,
.ch.promo h2,
.ch.promo h3,
.ch.promo h4,
.ch.promo h5,
.ch.promo h6,
.ch.promo p,
.ch.promo li,
.ch.promo ul,
.ch.promo ol,
.ch.promo div,
.ch.promo span,
.ch.promo a,
.ch.promo button,
.ch.register-end h1,
.ch.register-end h2,
.ch.register-end h3,
.ch.register-end h4,
.ch.register-end h5,
.ch.register-end h6,
.ch.register-end p,
.ch.register-end li,
.ch.register-end ul,
.ch.register-end ol,
.ch.register-end div,
.ch.register-end span,
.ch.register-end a,
.ch.register-end button {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}

.ch.register h1,
.ch.register h2,
.ch.register h3,
.ch.register h4,
.ch.register h5,
.ch.register h6,
.ch.promo h1,
.ch.promo h2,
.ch.promo h3,
.ch.promo h4,
.ch.promo h5,
.ch.promo h6,
.ch.register-end h1,
.ch.register-end h2,
.ch.register-end h3,
.ch.register-end h4,
.ch.register-end h5,
.ch.register-end h6 {
  font-weight: 700;
}

.ch.register .btn,
.ch.promo .btn,
.ch.register-end .btn {
  height: 70px;
  border-radius: 16px;
}

.ch.register .btn,
.ch.register .btn span,
.ch.promo .btn,
.ch.promo .btn span,
.ch.register-end .btn,
.ch.register-end .btn span {
  font-weight: 300;
  font-size: 30px;
}

.ch.register .link-card-promo h2,
.ch.promo .link-card-promo h2,
.ch.register-end .link-card-promo h2 {
  color: #38a8ae;
  margin: 20px 0;
}

.ch.register .link-card-promo .how-split-right h3,
.ch.promo .link-card-promo .how-split-right h3,
.ch.register-end .link-card-promo .how-split-right h3 {
  color: #ff4219;
}

.ch.register .title-main,
.ch.promo .title-main,
.ch.register-end .title-main {
  color: #ff4219;
}

.ch.register .login-link,
.ch.promo .login-link,
.ch.register-end .login-link {
  color: #38a8ae;
}

.ch.register .register-widget,
.ch.promo .register-widget,
.ch.register-end .register-widget {
  background-image: none;
}

.ch.register .register-widget h2,
.ch.promo .register-widget h2,
.ch.register-end .register-widget h2 {
  color: #ff4219;
}

.ch.register .register-widget .account-type.active,
.ch.promo .register-widget .account-type.active,
.ch.register-end .register-widget .account-type.active {
  border: 2px solid #ff4219;
}

.ch.register .register-widget .text-red-light,
.ch.promo .register-widget .text-red-light,
.ch.register-end .register-widget .text-red-light {
  color: #ff4219 !important;
}

.ch.register .register-widget .btn-register,
.ch.promo .register-widget .btn-register,
.ch.register-end .register-widget .btn-register {
  background-color: #ff4219;
}

.ch.register .spinner .lds-grid div,
.ch.promo .spinner .lds-grid div,
.ch.register-end .spinner .lds-grid div {
  background: #ff4219;
}

.ch.register .reg-end .section-title,
.ch.promo .reg-end .section-title,
.ch.register-end .reg-end .section-title {
  color: #38a8ae;
}

.ch.register .reg-end .step-list-item .done,
.ch.promo .reg-end .step-list-item .done,
.ch.register-end .reg-end .step-list-item .done {
  color: #38a8ae;
}

.ch.register .reg-end .step-list-item .color,
.ch.promo .reg-end .step-list-item .color,
.ch.register-end .reg-end .step-list-item .color {
  color: #ff4219;
  font-weight: bold;
}

.ch.register .reg-end .choice h4,
.ch.promo .reg-end .choice h4,
.ch.register-end .reg-end .choice h4 {
  color: #ff4219;
}

.ch.register .reg-end .access-split,
.ch.promo .reg-end .access-split,
.ch.register-end .reg-end .access-split {
  background-color: #38a8ae;
  color: #ffffff;
}

.ch.register .reg-end .access-split h2,
.ch.promo .reg-end .access-split h2,
.ch.register-end .reg-end .access-split h2 {
  color: #ffffff;
}

.ch.register .reg-end .access-split p,
.ch.promo .reg-end .access-split p,
.ch.register-end .reg-end .access-split p {
  color: #ffffff;
}

.sr.register h1,
.sr.register h2,
.sr.register h3,
.sr.register h4,
.sr.register h5,
.sr.register h6,
.sr.register p,
.sr.register li,
.sr.register ul,
.sr.register ol,
.sr.register div,
.sr.register span,
.sr.register a,
.sr.register button,
.sr.promo h1,
.sr.promo h2,
.sr.promo h3,
.sr.promo h4,
.sr.promo h5,
.sr.promo h6,
.sr.promo p,
.sr.promo li,
.sr.promo ul,
.sr.promo ol,
.sr.promo div,
.sr.promo span,
.sr.promo a,
.sr.promo button,
.sr.register-end h1,
.sr.register-end h2,
.sr.register-end h3,
.sr.register-end h4,
.sr.register-end h5,
.sr.register-end h6,
.sr.register-end p,
.sr.register-end li,
.sr.register-end ul,
.sr.register-end ol,
.sr.register-end div,
.sr.register-end span,
.sr.register-end a,
.sr.register-end button {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}

.sr.register h1,
.sr.register h2,
.sr.register h3,
.sr.register h4,
.sr.register h5,
.sr.register h6,
.sr.promo h1,
.sr.promo h2,
.sr.promo h3,
.sr.promo h4,
.sr.promo h5,
.sr.promo h6,
.sr.register-end h1,
.sr.register-end h2,
.sr.register-end h3,
.sr.register-end h4,
.sr.register-end h5,
.sr.register-end h6 {
  font-weight: 800;
  text-transform: uppercase;
}

.sr.register p,
.sr.promo p,
.sr.register-end p {
  color: #000;
}

.sr.register .btn,
.sr.promo .btn,
.sr.register-end .btn {
  height: 70px;
  border-radius: 16px;
}

.sr.register .btn,
.sr.register .btn span,
.sr.promo .btn,
.sr.promo .btn span,
.sr.register-end .btn,
.sr.register-end .btn span {
  font-weight: 800;
  font-size: 30px;
  text-transform: uppercase;
  background-color: #e51f34 !important;
}

.sr.register .link-card-promo .hr-line,
.sr.promo .link-card-promo .hr-line,
.sr.register-end .link-card-promo .hr-line {
  background: #000;
  height: 1px;
}

.sr.register .link-card-promo h2,
.sr.promo .link-card-promo h2,
.sr.register-end .link-card-promo h2 {
  color: #892890;
  margin: 20px 0;
}

.sr.register .link-card-promo h4,
.sr.promo .link-card-promo h4,
.sr.register-end .link-card-promo h4 {
  color: #e51f34;
  font-weight: 700;
}

.sr.register .link-card-promo .how-split-right h3,
.sr.promo .link-card-promo .how-split-right h3,
.sr.register-end .link-card-promo .how-split-right h3 {
  color: #e51f34;
  text-transform: none;
}

.sr.register .title-main,
.sr.promo .title-main,
.sr.register-end .title-main {
  color: #e51f34;
}

.sr.register .login-link,
.sr.promo .login-link,
.sr.register-end .login-link {
  color: #e51f34;
  font-weight: 700;
}

.sr.register .register-widget,
.sr.promo .register-widget,
.sr.register-end .register-widget {
  background-image: none;
}

.sr.register .register-widget-container h2,
.sr.promo .register-widget-container h2,
.sr.register-end .register-widget-container h2 {
  color: #892890;
  font-size: 40px;
}

.sr.register .register-widget .account-type.active,
.sr.promo .register-widget .account-type.active,
.sr.register-end .register-widget .account-type.active {
  border: 2px solid #e51f34;
}

.sr.register .register-widget .text-red-light,
.sr.promo .register-widget .text-red-light,
.sr.register-end .register-widget .text-red-light {
  color: #e51f34 !important;
}

.sr.register .register-widget .btn-register,
.sr.promo .register-widget .btn-register,
.sr.register-end .register-widget .btn-register {
  background-color: #e51f34;
}

.sr.register .spinner .lds-grid div,
.sr.promo .spinner .lds-grid div,
.sr.register-end .spinner .lds-grid div {
  background: #e51f34;
}

.sr.register .reg-end .section-title,
.sr.promo .reg-end .section-title,
.sr.register-end .reg-end .section-title {
  color: #e51f34;
}

.sr.register .reg-end .step-list-item .done,
.sr.promo .reg-end .step-list-item .done,
.sr.register-end .reg-end .step-list-item .done {
  color: #e51f34;
}

.sr.register .reg-end .step-list-item .color,
.sr.promo .reg-end .step-list-item .color,
.sr.register-end .reg-end .step-list-item .color {
  color: #e51f34;
  font-weight: bold;
}

.sr.register .reg-end .choice h4,
.sr.promo .reg-end .choice h4,
.sr.register-end .reg-end .choice h4 {
  color: #e51f34;
}

.sr.register .reg-end .access-split,
.sr.promo .reg-end .access-split,
.sr.register-end .reg-end .access-split {
  background-color: #e51f34;
  color: #ffffff;
}

.sr.register .reg-end .access-split h2,
.sr.promo .reg-end .access-split h2,
.sr.register-end .reg-end .access-split h2 {
  color: #ffffff;
}

.sr.register .reg-end .access-split p,
.sr.promo .reg-end .access-split p,
.sr.register-end .reg-end .access-split p {
  color: #ffffff;
}

.agent.register .register-widget,
.agent.promo .register-widget,
.agent.register-end .register-widget {
  background-image: none;
  padding: 80px 0;
}

.agent-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 150px;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer .text {
  color: #57606F;
  font-size: 16px;

}

.timer .value {
  font-size: 40px;
  font-family: "Fredoka One", cursive;
}

.timer .info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}


.timerSubHeader {
  color: #EA212D;
  margin: 20px 0px;
  text-align: center;
  font-weight: bold !important
}

.timerBottomTextA {
  color: #57606F;
  margin-top: 20px;
  text-align: center;
}

.timerBottomTextB {
  color: #57606F;
  text-align: center;
}

.timerHeader {
  font-family: "Fredoka One", cursive;
  font-size: 40px;
  color: #454f5b;
  text-align: center;
  max-width: 850px;
  margin-right: auto;
  margin-left: auto;
}